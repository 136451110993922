import { create } from "zustand";

// Utility function to remove a key from an object
const removeKey = (obj, theKey) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => key !== theKey));

const useUnreadChatRoomStore = create((set) => ({
  unreadchatRoomsMsg: {},  // Stores chat rooms by room ID or JID
  
  // Add a new message to a particular chat room
  addUnreadMessageToRoom: (roomId, message) =>
    set((state) => ({
      unreadchatRoomsMsg: {
        ...state.unreadchatRoomsMsg,
        [roomId]: [...(state.unreadchatRoomsMsg[roomId] || []), message],  // Append new message
      },
    })),
  
  // Mark all messages in a chat room as read
  markRoomAsRead: (roomId) =>
    set((state) => {
      const currentMessages = state.unreadchatRoomsMsg[roomId];
      
      // If there are no messages for the room ID, return the current state without changes
      if (!currentMessages || currentMessages.length === 0) {
        return state;
      }

      // Clear messages for this room ID to indicate they are read
      return {
        unreadchatRoomsMsg: {
          ...state.unreadchatRoomsMsg,
          [roomId]: [],  // Clear messages for this room ID
        },
      };
    }),

  // Mark a specific message in the room as unread
  markLastMessageAsUnread: (roomId, lastMessage) =>
    set((state) => ({
      unreadchatRoomsMsg: {
        ...state.unreadchatRoomsMsg,
        [roomId]: [lastMessage],  // Set the last message as unread
      },
    })),

  // Remove the entire chat room from the store if needed
  removeChatRoom: (roomId) =>
    set((state) => ({
      unreadchatRoomsMsg: removeKey(state.unreadchatRoomsMsg, roomId),  // Remove the room
    })),
    resetUnreadChatRoomStore: () => set({
        unreadchatRoomsMsg: {},
    }),
}));

export default useUnreadChatRoomStore;
