import { useMutation, useQuery } from "react-query";
import api from "./axiosInterceptor";
import axios from 'axios';
import { getAPiEndpoint } from "../config/apiEndpoints";
import { name } from "stanza/platform";

async function fetchData(url, headers) {
  const response = await api?.get(url, { headers });
  return response?.data;
}

export const getmyconnection=async (userInformation,token) => {
  try {
    const response = await axios.get(getAPiEndpoint("getmyconnections", `${userInformation[0]?.samAccountName}`), {
      headers: { Authorization: `Bearer ${token}` },
    });
  
    return response.data;
  } catch (error) {
    console.log("Error in getmyConnections :",error);
  }
  }

  export const deleteUserFromRoster=async (userInformation,jid) => {
    try {
      const response = await api.delete(getAPiEndpoint("deleteUserFromRoster", `users/${userInformation[0]?.samAccountName}/roster/${jid}`));
      return response.data;
    } catch (error) {
      console.log("Error in delete user from Roaster :",error);
    }
    }

export const addRosterForSenderAndReceiver = async (token, item, userInformation, payloadReceiver, payloadSender) => {
  
  try {
    // Create the API requests
    const addrosterReceiverPromise = axios.post(
      getAPiEndpoint("addRoster", `${item?.samAccountName}/roster`),
      payloadReceiver,
      { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 }
    );

    const addrosterSenderPromise = axios.post(
      getAPiEndpoint(
        "addRoster",
        `${userInformation?.[0]?.samAccountName}/roster`
      ),
      payloadSender,
      { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 }
    );

    // Run both requests concurrently
    const results = await Promise.allSettled([addrosterReceiverPromise, addrosterSenderPromise]);

    // Handle the results
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        console.log(`API ${index === 0 ? "Receiver" : "Sender"} Success:`, result.value.data);
      } else {
        console.error(`API ${index === 0 ? "Receiver" : "Sender"} Failed:`, result.reason);
      }
    });

  } catch (error) {
    console.error("Error in adding roster:", error);
  }
};
export const passwordChanged = async (payload,token) => {
  try {
    const response =  await axios.post(
      getAPiEndpoint("changepassword"),payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
    //console.log("Password updated successfully");
  } catch (error) {
    console.error("Password change error:", error);
  }
};

export const regenerateOtp = async (payload ,token) => {
  try {
    const response= await axios.post(
      getAPiEndpoint("regenerateOtp"),payload,
      // {
      //   headers: { Authorization: `Bearer ${token}` },
      // }
    );
    return response.data;
  } catch (error) {
    return error;
    console.error("Password change error:", error);
  }
};

export const markAllMessagesRead = async (payload) => {
  try {
    const response = await api.post(getAPiEndpoint('markallmessagesread'),payload,
    // {
    //   headers: { Authorization: `Bearer ${token}` },
    // }
  );
    return response.data;  // Assuming the response contains some data you want to handle
  } catch (error) {
    console.error("Error marking all messages as read:", error);
    throw error; // Throw the error so you can handle it where you call the function
  }
};
export const updateSettingsPost = async (email, updatedConfig, token) => {
  try {
    await axios.post(
      getAPiEndpoint("updateSettings"),
      {
        jid: email,
        settings: JSON.stringify(updatedConfig), // Use the updated config
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log("Settings updated successfully");
  } catch (error) {
    console.error("Error updating settings:", error);
  }
};

async function updateData(method, url, data, headers) {
  const response = await api({
    method,
    url: url,
    data,
    headers,
  });
  return response.data;
}

export function useGet(name, endpoint, action) {
  return useQuery(
    [name, endpoint],
    async () => {
      const headers = {};
      try {
        return fetchData(endpoint, headers);
      } catch (error) {
        console.error(error);
      }
    },
   
    {...action }
  );
}

export function useUpdate(method, endpoint, action, headers) {
  return useMutation(
    async (data) => {
      try {
        return updateData(method, endpoint, data, headers);
      } catch (error) {
        console.error(error);
      }
    },
    {
      ...action,
    }
  );
}
