import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ProfileDetails from "../../../../../common/components/profile-details";
import { getInitials } from "../../../../../services/dataService";
import useChatStore from "../../../../../store/chatStore";
import useUnreadMessageStore from "../../../../../store/unreadMessageStore";
import useXmppStore from "../../../../../store/xmppStore";
import useChatModel from "../../../viewModel/useChatModel";
import useSettingModel from "../../../../settings/viewModel/useSettingModel";
import { markAllMessagesRead } from "../../../../../services/useApiService";
import useLoginStore from "../../../../../store/loginStore";

function formatTimestamp(timestamp) {
  const givenDate = moment(timestamp);
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  if (givenDate.isSame(today, "d")) {
    return givenDate.format("hh:mm A");
  }
  if (givenDate.isSame(yesterday, "d")) {
    return "Yesterday";
  }
  return givenDate.format("MM/DD/YYYY");
}

const parsedMessages = (message) => {
  if (typeof message === "string") {
    try {
      const parsedMessage = JSON.parse(message);
      if (typeof parsedMessage === "object" && parsedMessage !== null) {
        return parsedMessage.message || parsedMessage.name;
      }
    } catch (e) {
      return message;
    }
  }
  return message;
};

// const calculateUnreadCount = (myUnread, chatItem) => {
//   return (myUnread?.length === undefined ? 0 : myUnread?.length || 0) + (chatItem?.unreadCount || 0);
// };

// const calculateUnreadCount = (myUnread, chatItem) => {
//   const unreadCount =
//     (myUnread?.length === undefined ? 0 : myUnread?.length || 0) +
//     (chatItem?.unreadCount || 0);
//   if (chatItem?.unreadCount>0) {
//     chatItem.unreadCount = 0; // Reset chatItem unreadCount
//   }

//   return unreadCount;
// };

export default function ChatItem({ chatItem, handleClick, isTyping }) {
  const setDefaultChatDetailsState = useXmppStore(
    (state) => state.setDefaultChatDetailsState
  );
  const { showPreview, setShowPreview } = useChatModel();
  const { id } = useParams();
  const { setMessage, setMessages } = useChatModel();
  const { config } = useSettingModel();
  const navigate = useNavigate();
  const unreadMsg = useUnreadMessageStore((state) => state.unreadMsg);
  const handleMsgRead = useUnreadMessageStore((state) => state.handleMsgRead);
  const myUnread =
    unreadMsg[chatItem?.samAccountName?.toLowerCase() + "@cogencis.com"];
  // console.log("myUnread-->", myUnread);
  // console.log("myUnread unreadMsg-->", unreadMsg);
  const [activeChats, setActiveChats] = useState("");
  const lastMessage =
    myUnread?.length > 0 ? myUnread[myUnread?.length - 1] : null;
  const { isMarkedUnread, setIsMarkedUnread } = useChatStore();
  const presence = useChatStore();
  const location = useLocation();
  const [openProfile, setOpenProfile] = useState(false);
  const [userName, setUserName] = useState("");
  const setpageChatHistory = useChatStore((state) => state.setpageChatHistory);
  const { markedUnreadChats, setMarkedUnreadChat } = useChatStore();
  const { userInformation } = useLoginStore();
  // const unreadCount = calculateUnreadCount(myUnread, chatItem);

  const handleProfileView = () => {
    setUserName(chatItem.samAccountName);
    setOpenProfile(true);
  };

  const handleClose = () => {
    setOpenProfile(false);
  };

  const showOnline =
    presence?.presence[
      chatItem?.samAccountName?.toLowerCase() + "@cogencis.com"
    ];

  const handleChatClick = async (user, showOnline) => {
    setActiveChats(user?.samAccountName);
    const currentJid = user.samAccountName.toLowerCase() + "@cogencis.com";
    setDefaultChatDetailsState();
    setMessage("");
    setMessages([]);
    setpageChatHistory(1);
    if (user) {
      const userData = { ...user, showOnline };
      navigate(`/chat/${user?.samAccountName}`, { state: userData });
      handleMsgRead(currentJid);
      const payload = {
        toId: userInformation[0]?.samAccountName + "@cogencis.com",
        fromId: user?.samAccountName + "@cogencis.com",
      };
      if (user?.unreadCount > 0) {
        await markAllMessagesRead(payload);
      }
    }
  };

  useEffect(() => {
    const currentJid = chatItem.samAccountName?.toLowerCase() + "@cogencis.com";
    let activeChatJid = `${location?.pathname
      .split("/")
      .pop()
      .toLowerCase()}@cogencis.com`;
    const payload = {
      toId: userInformation[0]?.samAccountName + "@cogencis.com",
      fromId: chatItem?.samAccountName + "@cogencis.com",
    };
    if (
      activeChatJid === currentJid &&
      !markedUnreadChats[currentJid] &&
      myUnread?.count > 0
    ) {
      handleMsgRead(currentJid);
      // if (chatItem?.unreadCount) {
      //   markAllMessagesRead(payload);
      // }

      if (markedUnreadChats[currentJid]) {
        setMarkedUnreadChat(currentJid, false);
      }
    }
  }, [
    // chatItem.samAccountName,
    // handleMsgRead,
    // markedUnreadChats,
    // setMarkedUnreadChat,
    myUnread?.count,
    //location?.pathname,
  ]);

  let titleStyle = {};

  if (id !== chatItem.samAccountName && myUnread?.count > 0) {
    titleStyle = { fontSize: 18 };
  }

  return (
    <>
      <li
        className={`item ${
          location?.state?.samAccountName?.trim().toLocaleLowerCase() ===
          chatItem.samAccountName?.trim().toLocaleLowerCase()
            ? "active"
            : ""
        }`}
        onClick={() =>
          id !== chatItem.samAccountName &&
          handleChatClick(chatItem, showOnline)
        }
      >
        <div className="mod-logo-text">
          <div className="img-wrap" onClick={handleProfileView}>
            {chatItem.thumbnailPhoto ? (
              <img
                src={`data:image/png;base64,${chatItem.thumbnailPhoto}`}
                alt="profile"
              />
            ) : (
              <span className="img-text">{getInitials(chatItem.name)}</span>
            )}

            {!showOnline ? (
              <span className="badge grey"></span>
            ) : (
              <span className={`badge-icon ${showOnline} typ-bottom`}></span>
            )}
          </div>
          <div className="head-wrap">
            <h4 className="title" style={titleStyle}>
              {chatItem.name}
            </h4>
            <p className="desc">
              {config?.notification?.Show_Typing_Indicator
                ? isTyping
                  ? "Typing..."
                  : parsedMessages(chatItem?.message)
                : parsedMessages(chatItem?.message)}
            </p>{" "}
          </div>
          <div className="cta-timeline-wrap">
            {chatItem?.sentDate && !lastMessage ? (
              <p className="lasttimeline">
                {formatTimestamp(chatItem?.sentDate)}
              </p>
            ) : lastMessage ? (
              <p className="timeline">
                {moment(lastMessage?.time).format("hh:mm A")}
              </p>
            ) : (
              <></>
            )}
            {id !== chatItem.samAccountName && myUnread?.count > 0 ? (
              <span className="count">{myUnread?.count}</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </li>
      {openProfile && (
        <div className="bs-modal">
          <div className="modal-overlay" onClick={handleClose}>
            <div className="modal-content right-wrap full-page">
              <ProfileDetails
                handleClose={handleClose}
                name={userName}
                showOnline={showOnline}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
