import axios from "axios";
import useLoginStore from "../store/loginStore";
import reLoginService from "./relogin";
import { getAuthData } from '../services/auth';

const api = axios.create({});
api.interceptors.request.use((config) => {
  if (!config.url.includes("/login")) {
    config.headers["Authorization"] = `Bearer ${getAuthData()?.token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status == 401) {
     await reLoginService();
    }

    return error?.response;
  }
);

export default api;
