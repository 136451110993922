import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

class ReactAppWrapper extends HTMLElement {
  connectedCallback() {
    const root = createRoot(this);
    const queryClient = new QueryClient();
    // To retrieve props from the custom element's attributes
    const message = this.getAttribute('message') || "Default message"; // Provide a default value if not set
    console.log("Message-prop:", message);
    root.render(
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    );
    this._root = root;
    console.log("process.env.NODE_ENV--->", process.env.NODE_ENV);
    console.log("process.env.REACT_APP_NODE_ENV---g>", process.env.REACT_APP_NODE_ENV);
    if (process.env.NODE_ENV === "production") {
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
          navigator.serviceWorker
              .register("/service-worker.js")
              .then((registration) => {
                registration.onupdatefound = () => {
                  const installingWorker = registration.installing;
                  if (installingWorker) {
                    installingWorker.onstatechange = () => {
                      if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                          console.log("New content is available; please refresh.");
                          //Todo  commented to remove the alert  validate later.
                          // alert("New content available. Please refresh.");
                          window.location.reload();
                        } else {
                          console.log("Content is cached for offline use.");
                        }
                      }
                    };
                  }
                };
              })
              .catch((error) => {
                // alert("Service Worker register failed");
                console.error("Service Worker registration failed:", error)
              });
        });
      }else{
        console.error("can not find service worker")
        // alert("can not find service worker");
      }
    }
  }

  disconnectedCallback() {
    if (this._root) {
      this._root.unmount();
    }
  }
}
customElements.define("react-app", ReactAppWrapper);
