
// import Cookies from 'js-cookie';

// export const setAuthData = (authData) => {
//   const { token, expiry, refreshToken, userName } = authData;

//   const expiresInDays = new Date(expiry)
//     const isLocalhost = window.location.hostname === 'localhost';
//     const domain = isLocalhost ? 'localhost' : process.env.REACT_APP_COOKIE_DOMAIN || '';
//     const cookieOptions = {
//         domain,
//         expires: expiresInDays,
//         path: '/',
//         sameSite: isLocalhost ? '' : 'None', 
//         secure: window.location.protocol === 'https:' && !isLocalhost, // Only set secure in HTTPS production
//       };

//   Cookies.set('token', token, cookieOptions);
//   Cookies.set('expiry', expiry, cookieOptions);
//   Cookies.set('refreshToken', refreshToken, cookieOptions);
//   Cookies.set('userName', userName, cookieOptions);
// };

// export const getAuthData = () => ({
//   token: Cookies.get('token'),
//   expiry: Cookies.get('expiry'),
//   refreshToken: Cookies.get('refreshToken'),
//   userName: Cookies.get('userName'),
// });

// export const clearAuthData = () => {
//   const isLocalhost = window.location.hostname === 'localhost';
//   const domain = isLocalhost ? 'localhost' : process.env.REACT_APP_COOKIE_DOMAIN || '';

//   Cookies.remove('token', { domain, path: '/' });
//   Cookies.remove('expiry', { domain, path: '/' });
//   Cookies.remove('refreshToken', { domain, path: '/' });
//   Cookies.remove('userName', { domain, path: '/' });
// };

import Cookies from 'js-cookie';

// Storage types
const STORAGE_TYPES = {
  COOKIE: 'cookie',
  LOCAL_STORAGE: 'localStorage',
  SESSION_STORAGE: 'sessionStorage',
};

// Get storage type from environment variable or default to cookie
const STORAGE_TYPE = process.env.REACT_APP_STORAGE_TYPE || STORAGE_TYPES.COOKIE;

// Common utility to get storage instance based on type
const getStorageInstance = (storageType) => {
  switch (storageType) {
    case STORAGE_TYPES.LOCAL_STORAGE:
      return localStorage;
    case STORAGE_TYPES.SESSION_STORAGE:
      return sessionStorage;
    default:
      return null; // Cookies don't use a global storage object
  }
};

// Cookie configuration helper
const getCookieOptions = (expiry) => {
  const isLocalhost = window.location.hostname === 'localhost';
  const domain = isLocalhost ? 'localhost' : process.env.REACT_APP_COOKIE_DOMAIN || '';
  return {
    domain,
    expires: new Date(expiry),
    path: '/',
    sameSite: isLocalhost ? '' : 'None',
    secure: window.location.protocol === 'https:' && !isLocalhost,
  };
};

// Set auth data
export const setAuthData = (authData) => {
  const { token, expiry, refreshToken, userName, } = authData;

  if (STORAGE_TYPE === STORAGE_TYPES.COOKIE) {
    const cookieOptions = getCookieOptions(expiry);
    Cookies.set('token', token, cookieOptions);
    Cookies.set('expiry', expiry, cookieOptions);
    Cookies.set('refreshToken', refreshToken, cookieOptions);
    Cookies.set('userName', userName, cookieOptions);
  } else {
    const storage = getStorageInstance(STORAGE_TYPE);
    if (storage) {
      storage.setItem('token', token);
      storage.setItem('expiry', expiry);
      storage.setItem('refreshToken', refreshToken);
      storage.setItem('userName', userName);
    }
  }
};

// Get auth data
export const getAuthData = () => {
  if (STORAGE_TYPE === STORAGE_TYPES.COOKIE) {
    return {
      token: Cookies.get('token'),
      expiry: Cookies.get('expiry'),
      refreshToken: Cookies.get('refreshToken'),
      userName: Cookies.get('userName'),
    };
  } else {
    const storage = getStorageInstance(STORAGE_TYPE);
    if (storage) {
      return {
        token: storage.getItem('token'),
        expiry: storage.getItem('expiry'),
        refreshToken: storage.getItem('refreshToken'),
        userName: storage.getItem('userName'),
      };
    }
    return null;
  }
};

// Clear auth data
export const clearAuthData = () => {
  if (STORAGE_TYPE === STORAGE_TYPES.COOKIE) {
    const cookieOptions = { domain: getCookieOptions().domain, path: '/' };
    Cookies.remove('token', cookieOptions);
    Cookies.remove('expiry', cookieOptions);
    Cookies.remove('refreshToken', cookieOptions);
    Cookies.remove('userName', cookieOptions);
  } else {
    const storage = getStorageInstance(STORAGE_TYPE);
    if (storage) {
      storage.removeItem('token');
      storage.removeItem('expiry');
      storage.removeItem('refreshToken');
      storage.removeItem('userName');
    }
  }
};
