import React, { forwardRef, useState } from "react";
import html2canvas from "html2canvas";

const ScreenCaptureBtn = forwardRef((props, ref) => {
  const [image, setImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  // console.log(props,"props");
  // const {setIsCaptured,iscaptured}=props
  

  const getImage = () => {
    if (!ref?.current) {
      console.error("Ref is not set");
      return;
    }
    
    html2canvas(ref.current, {
      useCORS: true,
      backgroundColor: "#181d2e",
      scale: window.devicePixelRatio,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      setImage(imgData);
      setShowPreview(true);
    });
    // setIsCaptured(true)
  };

  const handleSave = () => {
    if (!image) return;
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB').replace(/\//g, "-"); // Format date as DD-MM-YYYY
    const formattedTime = now.toLocaleTimeString('en-GB').replace(/:/g, "-"); // Format time as HH-MM-SS
    const fileName = `screenshot_${formattedDate}_${formattedTime}.png`;
    const downloadLink = document.createElement("a");
    downloadLink.href = image;
    downloadLink.download = fileName; 
    downloadLink.click();
    setShowPreview(false);
   
  };
  

  return (
    <>
      <li className="item">
        <button onClick={getImage}>
          <i className="icon capture"></i>
          <span className="cta-text">Capture Screenshot</span>
        </button>
      </li>

      {showPreview && image && (
        <div className="bs-modal" onClick={handleSave}>
          <div className="modal-overlay">
            <div className="modal-content typ-screen-capture">
              <div className="head-wrap cm-hidden">
                <span className="cm-hiiden-flex">
                  <i className="close-modal"></i>
                </span>
              </div>
              <div className="capture-preview">
                <img src={image} alt="Preview" className="preview-image" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default ScreenCaptureBtn;
