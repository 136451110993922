import {create} from "zustand";

const useChatStore = create((set) => ({
  messages: {},
  presence: {},
  searchValue: "",
  searchMsgValue:"",
  pageChatHistory: 1,
  showType: {},
  isMarkedUnread: false,
  markedUnreadChats: {}, // Store for unread chat statuses
  user: null,
  setActiveChat: (samAccountName) => set({ user: samAccountName }),
  setMarkedUnreadChat: (jid, isUnread) => 
    set((state) => ({
      markedUnreadChats: {
        ...Object.keys(state.markedUnreadChats).reduce((acc, key) => {
          acc[key] = false; // Set all other chats to false
          return acc;
        }, {}),
        [jid]: isUnread, // Update the specific chat's unread status
      },
    })),
  setIsMarkedUnread: (value) =>
    set((state) => ({ ...state, isMarkedUnread: value })),
  addMessage: (jid, message) =>
    set((state) => ({
      messages: {
        ...state.messages,
        [jid]: [...(state.messages[jid] || []), message],
      },
    })),
  removeMessage: (jid) =>
    set((state) => {
      if (!state.messages || !Object.keys(state.messages).length)
        return { messages: state.messages };

      const { [jid]: removed, ...remainingMessages } = state.messages;
      return { messages: remainingMessages };
    }),
  resetMessage: (jid) =>
    set((state) => ({
      messages: {
        ...state.messages,
        [jid]: [...(state.messages[jid] || []), {}],
      },
    })),
  updatePresence: (jid, status) =>
    set((state) => ({
      presence: {
        ...state.presence,
        [jid]: status,
      },
    })),
  addShowType: (value) => set(() => ({ showType: value })),
  clearReadmsg: (value) => set(() => ({ messages: value })),
  setpageChatHistory: (value) => set(() => ({ pageChatHistory: value })),
  setSearchValue: (value) => set(() => ({ searchValue: value })),
  clearSerchVal: () => set({ searchValue: "" }),
  setSearchMsgValue: (value) => set(() => ({ searchMsgValue: value })),
  clearSerchMsgVal: () => set({ searchMsgValue: "" }),
    resetChatStore: () => set({
        messages: {},
        presence: {},
        searchValue: "",
        searchMsgValue:"",
        pageChatHistory: 1,
        showType: {},
        isMarkedUnread: false,
        markedUnreadChats: {}, // Store for unread chat statuses
        user: null,
    }),
}));

export default useChatStore;
