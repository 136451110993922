//@ts-nocheck
import {create} from "zustand";
import * as XMPP from "stanza";
import moment from "moment";
import useLoginStore from "./loginStore";
import {sendNotification} from "../services/notificationService";
import {createJSONStorage, persist} from "zustand/middleware";
import useUnreadMessageStore from "./unreadMessageStore";
import useChatStore from "./chatStore";
import useConfigStore from "./settingsStore";
import {getInitials} from "../services/dataService";
import "react-toastify/dist/ReactToastify.css";
import {playNotificationSound} from "../services/playNotificationSound";
import useContactListStore from "./contactList";
import useUnreadChatRoomStore from "./unreadChatRoomStore";
import {getAuthData} from "../services/auth";
import getDeviceType from "../services/deviceTypeService";
import {filterInArrayOfObject} from "../config/utils";
import api from "../services/axiosInterceptor";
import {getAPiEndpoint} from "../config/apiEndpoints";

const processedMessageIds = new Set();
const Utils = {
  reviveData: (key, value) => {
    // Example: Converts ISO date strings to JavaScript Date objects
    if (
      typeof value === "string" &&
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
    ) {
      return new Date(value);
    }
    return value;
  },
};

export const usePresenceStore = create(
  persist(
    (set) => ({
      myPresence: undefined,
      setPresence: (isOnline) =>
        set((state) => ({
          myPresence: isOnline,
        })),
    }),
    {
      name: "presence-store", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
const originalTitle = document.title;
const originalFavicon = document
  ?.querySelector("link[rel='icon']")
  ?.getAttribute("href");
  let flashInterval = null;
const useXmppStore = create((set, get) => ({
  xmppClient: null,
  isConnected: false,
  availabilityStatus: usePresenceStore?.getState()?.myPresence || "online",
  onlineStatus: "offline",
  messages: [],
  typingStatus: false,
  isMessagesLoading: null,
  pageNo: 1,
  scrollToBottom: true,
  hasMore: true,
  areTyping: [],
  userCredentials: {},
  lastPresenceStatus: {},
  isFlashing: false,
  currentLocation: null,
  setLocation: (location) => set({ currentLocation: location }),
  flashTaskbar: () => {
    if (!document.hasFocus() && !flashInterval) {
      set({ isFlashing: true });
      const newFaviconUrl = originalTitle; 
      flashInterval = setInterval(() => {
        // Toggle document title
        document.title =
          document.title === originalTitle ? "New Message!" : originalTitle;
        const currentFavicon = document.querySelector("link[rel='icon']");
        currentFavicon.setAttribute(
          "href",
          currentFavicon.getAttribute("href") === originalFavicon
            ? newFaviconUrl
            : originalFavicon
        );
      }, 1000);
      window.addEventListener(
        "focus",
        () => {
          clearInterval(flashInterval);
          flashInterval = null;
          set({ isFlashing: false });
          document.title = originalTitle;
          document
            .querySelector("link[rel='icon']")
            .setAttribute("href", originalFavicon);
        },
        { once: true }
      );
    }
  },

  stopFlashing: () => {
    clearInterval(flashInterval);
    set({ isFlashing: false });
    document.title = originalTitle;
  },

  setUserCredentials: (payload) => set({ userCredentials: payload }),
  setAvailabilityStatus: (payload) => set({ availabilityStatus: payload }),
  addMessages: (payload) =>
    set((state) => ({
      messages: Array.isArray(payload)
        ? [...state.messages, ...payload] // If payload is an array, spread it
        : [...state.messages, payload], // If payload is a single message, add it directly
    })),

  clearMessages: () =>
    set(() => ({
      messages: [],
    })),
  setPageNo: (payload) =>
    set(() => ({
      pageNo: payload,
    })),
  setIsMessagesLoading: (payload) =>
    set(() => ({
      isMessagesLoading: payload,
    })),
  setScrollToBottom: (payload) =>
    set(() => ({
      scrollToBottom: payload,
    })),
  setHasMore: (payload) =>
    set(() => ({
      hasMore: payload,
    })),
  setDefaultChatDetailsState: () =>
    set(() => ({
      messages: [],
      isMessagesLoading: null,
      pageNo: 1,
      scrollToBottom: true,
      hasMore: true,
    })),

  connectXMPP: async (username, password, deviceType) => {
    
    const {isConnected =  false} = useXmppStore.getState();
    console.log("connectXMPP--->Start-->", isConnected, new Date())
    const {xmppClient: xmppClientTemp} = useXmppStore.getState();
    if (isConnected && xmppClientTemp){
      // xmppClientTemp.sendPresence({
      //   status: usePresenceStore?.getState()?.myPresence || "online",
      //   show: "chat",
      //   priority: 1,
      // });
      return  
    }
    const getStorageData=getAuthData();
    if (!username && getStorageData && getStorageData?.userName){
      username = getStorageData.userName
      password = getStorageData.token;
      deviceType = getDeviceType()
    }
    
    
    const delay = (delayInms) => {
      return new Promise(resolve => setTimeout(resolve, delayInms));
    };
    try {
      await xmppClientTemp?.disableCarbons()
    } catch (e) {
      console.log("xmppClientTemp.disconnect()-Error", e);
    }
    try {
      xmppClientTemp?.disconnect();
      delay(3000)
      set({ isConnected: false, onlineStatus: "offline" });
    } catch (e) {
      console.log("xmppClientTemp.disconnect()-Error", e);
    }
    return new Promise((resolve, reject) => {
      const transportConfig = process.env.REACT_APP_SOCKET_URL.includes("https")
        ? { bosh: process.env.REACT_APP_SOCKET_URL }
        : { websocket: process.env.REACT_APP_SOCKET_URL };
      // console.log("password-->", password)
      const xmppClient = XMPP.createClient({
        jid: username + "@cogencis.com",
        password,
        transports: transportConfig,
        lang: "eng",
        //resource: deviceType,
        resource: deviceType + "-" + Date.now(),
        useStreamManagement: true, // Enable stream management for reliability
        allowResumption: true,
        sendReceipts: true,
        // autoReconnect: true,
        // maxReconnectBackoff: 30,
        // reconnect: true,               // Enable automatic reconnection
        // keepAlive: true,
      });


      console.log("enableKeepAlive-XMPP-1-");
      xmppClient.enableKeepAlive({interval: 30, timeout: 60});
      console.log("enableKeepAlive-XMPP-2--");
      
      xmppClient.sm.cache((state) => {
        sessionStorage.setItem("cachedSM", JSON.stringify(state));
      });

      xmppClient.on("session:started", async () => {
        console.log("session:started---->", new Date()) 
        xmppClient?.disableCarbons();
        xmppClient.enableCarbons().then((resp) => {  
          console.log(`Carbon enabled for ${username}/${deviceType}`);
        }).catch((error) => {
          console.log(`CarbonError for ${username}/${deviceType}: `, error);
        });
        xmppClient.sendPresence({
          status: usePresenceStore?.getState()?.myPresence || "online",
          show: "chat",
          priority: 1,
        });
        /*TODO Add logic to Add all the group join logic here.*/
        await delay(1000);
        allChatRoomList?.map((roomItems)=>{
          const {roomName = ""} = roomItems;
          if (roomName){
            const roomJid = roomName+process.env.REACT_APP_CHAT_GROUP_DOMAIN
            xmppClient.sendPresence({
              to: `${roomJid}/${username.toString().toLowerCase()}`, // Room JID with your desired nickname
              type: "available", // Presence type 'available' to join the room
            });
          }
        })
        
        set({ isConnected: true, onlineStatus: "online", xmppClient });
      });
      
      xmppClient.on("session:end", async () => {
        console.log("session:end---->", new Date()) 
      });
      
      xmppClient.on("session:bound", async () => {
        console.log("session:bound---->", new Date()) 
      });
      xmppClient.on("error", (err) => {
        console.error("XMPP connection error:", err);
        reject(err);
      });

      xmppClient.on("stanza", async (stanza) => {
        console.log("stanza--->", stanza)
        /*To Accept request*/
        // try {
        //   if (stanza && stanza?.type && stanza.type === "set" && stanza?.roster && stanza?.payloadType && stanza.payloadType === "roster") {
        //     console.log("stanza-----s-1->subscription-->")
        //     const {items = []} = stanza.roster;
        //     console.log("stanza-----s-2->subscription-->")
        //     if (items.length > 0) {
        //       const {subscription = "", jid = ""} = items[0];
        //       console.log("stanza-----s-3->subscription-->")
        //       if (subscription === "from" && jid) {
        //         console.log("stanza-----s-4->subscription-->", subscription)
        //         console.log("stanza-----s-5->jid-->", jid)
        //         /*Send presence to accept the incoming request.*/
        //         xmppClient.sendPresence({
        //           to: jid,
        //           type: 'subscribe',
        //         });
        //       }
        //     }
        //   }
        // } catch (e) {
        //   console.log('Error-To-Subscribe the user')
        // }

        try { /*To handle Multiple Login Presence*/
          if (stanza
              && stanza?.from
              && stanza?.to &&
              stanza?.type &&
              stanza?.type === "unavailable"
              && xmppClient
              && xmppClient?.jid
              && xmppClient?.jid?.toString()?.toLowerCase() === stanza?.to?.toString()?.toLowerCase()
          ) {
            xmppClient?.sendPresence({
              status: usePresenceStore?.getState()?.myPresence || "online",
              show: "chat",
              priority: 1,
            });
          }
        } catch (e) {
          console.log('Error-To-Send the user presence');
        }
    
        
        
        let { chatState, from, to } = stanza;
        from = from?.split("/")[0];
        if (chatState === "composing") {
          set((state) => {
            const isAlreadyTyping = state.areTyping.some(
              (typingEntry) =>
                typingEntry.from === from && typingEntry.to === to
            );

            if (!isAlreadyTyping) {
              return {
                areTyping: [...state.areTyping, { chatState, from, to }],
              };
            }
            return state;
          });
        }
        else if (chatState === "paused") {
          set((state) => ({
            areTyping: state.areTyping.filter(
              (typingEntry) =>
                typingEntry.from !== from || typingEntry.to !== to
            ),
          }));
        }
      });
      
      const { addUnreadMessageToRoom } = useUnreadChatRoomStore.getState();
      const { addUnreadMsg } = useUnreadMessageStore.getState();
      const flashTaskbar = useXmppStore.getState().flashTaskbar;
      const { chatRoomList : allChatRoomList } = useContactListStore.getState();
      //       // Acknowledge message delivery
      // xmppClient.on('message:acked', (msg) => {
      //   if (this.messageStorage.has(msg.id)) {
      //     this.messageStorage.set(msg.id, { ...this.messageStorage.get(msg.id), serverReceived: true });
      //   }
      // });
      //
      // xmppClient.on("message", async (msg) => {
      //   // console.log(msg,"msg_vikas");
      //
      //   const { updateContactList } = useContactListStore.getState();
      //   const { config } = useConfigStore.getState();
      //   const getmuteList = config.muteUserList;
      //   let chatOpenId = `${window.location?.pathname.split("/").pop().toLowerCase()}@cogencis.com`;
      //   const fromJid = msg.from.split("/")[0];
      //   if (msg.delay) return;
      //   if (msg.body) {
      //     flashTaskbar();
      //     if (msg.type === "groupchat") {
      //       addUnreadMessageToRoom(fromJid, {
      //         message: msg.body,
      //         from: msg.from,
      //         date: moment().valueOf(),
      //       });
      //       set((state) => ({
      //         messages: [
      //           ...state.messages,
      //           {
      //             body: msg.body,
      //             delay_stamp: msg.delay_stamp,
      //             from: msg.from,
      //             to: msg.to,
      //             type: msg.type,
      //           },
      //         ],
      //       }));
      //     } else {
      //       addUnreadMsg(fromJid, {
      //         message: msg.body,
      //         from: msg.from,
      //         date: moment().valueOf(),
      //       });
      //       const samAccountName=msg.from.split("@")[0];
      //       updateContactList(samAccountName + "@cogencis.com", msg.body);
      //       if (fromJid !== msg.to && !getmuteList.includes(fromJid)) {
      //         playNotificationSound();
      //         if (chatOpenId !== fromJid) {
      //           await xmppClient.getVCard(fromJid).then((vCard) => {
      //             const { fullName } = vCard;
      //             let pic = vCard.records ? vCard.records[1] : null;
      //             let imageUrl = null;
      //
      //             if (pic && pic.type && pic.data) {
      //               imageUrl = `data:${pic.type};base64,${pic.data}`;
      //             } else {
      //               let initials = getInitials(fullName);
      //               imageUrl = `https://via.placeholder.com/150?text=${initials}`;
      //             }
      //
      //             if (
      //               config?.notification.Chat_Alert_Sound ||
      //               config?.notification.Chat_Show_Notifications
      //             ) {
      //               sendNotification(fullName, msg.body, imageUrl,samAccountName);
      //             }
      //           });
      //         }
      //
      //         if (chatOpenId === fromJid) {
      //           set((state) => ({
      //             messages: [
      //               ...state.messages,
      //               {
      //                 date: moment().valueOf(),
      //                 message: msg.body,
      //                 toemail: msg.to,
      //                 fromemail: msg.from.split("/")[0],
      //                 attachment: null,
      //               },
      //             ],
      //           }));
      //           await xmppClient.sendMessage({
      //             to: msg.from.split("/")[0],
      //             id: msg.id,
      //             type: "chat",
      //             receipt: "received",
      //           });
      //         }
      //       }
      //     }
      //   }
      // });

      const addToMessages = (messageObj) => {
        set((state) => ({
          messages: [...state.messages, messageObj],
        }));
      };

      const addToChatRoomMessages = (messageObj) => {
        set((state) => ({
          messages: [...state.messages, messageObj],
        }));
      };

      xmppClient.on("message", async (msg) => {
        console.log("xmpp-msg-->", msg)
        // console.log("processedMessageIds-->", processedMessageIds)
        if (msg?.id && processedMessageIds.has(msg?.id)) {
          // Skip this message as it has already been processed
          return;
        }
        processedMessageIds.add(msg?.id);
        const { updateContactList } = useContactListStore.getState();
        const { currentLocation } = useXmppStore.getState();
        const { config } = useConfigStore.getState();
        const getmuteList = config?.muteUserList || [];
        const chatOpenId = `${window.location?.pathname
          .split("/")
          .pop()
          .toLowerCase()}@cogencis.com`;
        const fromJid = msg.from.split("/")[0];
        
        //TODO REVIEW DELAY 
        /*Added so that old notification don't show up again need to manage if message is received send ACK of the reception */
        if (msg.delay) return;

        const handleNotification = async (samAccountName, fullName, imageUrl, nickName, chat_section, chatType) => {
          console.log("fromJid---->2-->", fullName)
          if (
              // samAccountName =JabberId
              //     fullName, 
              //     imageUrl,
              //     chat_section
              // config?.notification.chat_section_Alert_Sound ||
              // config?.notification.chat_section_Show_Notifications
              config?.notification[`${chat_section}_Alert_Sound`] ||
              config?.notification[`${chat_section}_Show_Notifications`]
          ) {
            console.log("fromJid---->3-->", fullName)
            sendNotification(fullName, msg.body, imageUrl, nickName, samAccountName, chatType);
          } else if (!config) {
            console.log("fromJid---->4-->", fullName)
            sendNotification(fullName, msg.body, imageUrl, nickName, samAccountName, chatType);
          }
        };

        const processVCard = async (fromJid, chat_section) => {
          if (chat_section === "Groups") {
            const samAccountName = msg.from.split("@")[0];
            let userThumbNailPhoto = null;
            let userFullName = null;
            let nickName = null
            let chatDetails = null
            try {
              const {chatRoomList = []} = useContactListStore?.getState();
              if (chatRoomList?.length > 0) {
                chatDetails = chatRoomList.find((chatItem) => chatItem?.roomName?.toLowerCase() === samAccountName.toString().toLowerCase())
              }
              if (chatDetails) {
                const {subject: groupImage = null, naturalName: roomName = "Group"} = chatDetails;
                userThumbNailPhoto = groupImage
                userFullName = roomName
                nickName = getInitials(roomName);
              } else {
                userFullName = fromJid;
                nickName = getInitials(fromJid);
              }
            } catch (e) {
              userFullName = fromJid;
              nickName = getInitials(fromJid);
            }
            handleNotification(samAccountName, userFullName, userThumbNailPhoto, nickName, chat_section, "GROUP");
          } else {
            if (xmppClient) {
              const {contactsList} = useContactListStore.getState();
              let userAccountName = fromJid.split("@")[0];
              let userThumbNailPhoto = null;
              let userFullName = null;
              let nickName = null
              if (contactsList && contactsList.length > 0) {
                let index = contactsList.findIndex(item => item.samAccountName?.toString()?.toLowerCase() === userAccountName?.toString()?.toLowerCase())
                if (index !== -1) {
                  let userDetails = contactsList[index];
                  console.log("userDetails-->", userDetails)
                  userThumbNailPhoto = userDetails.thumbnailPhoto
                  userFullName = userDetails.name
                  nickName = getInitials(userFullName)
                  let imageUrl = userThumbNailPhoto ? `data:image/png;base64,${userThumbNailPhoto}` : null;
                  console.log("userDetails-->", userDetails)
                  handleNotification(
                      userAccountName?.toString()?.toLowerCase(),
                      userFullName,
                      imageUrl,
                      nickName,
                      chat_section,
                      "CHAT"
                  );
                  return;
                }
              }

              await xmppClient.getVCard(fromJid).then((vCard) => {
                const {fullName} = vCard;
                const pic = vCard.records ? vCard.records[1] : null;
                nickName = getInitials(fullName)
                const imageUrl = pic && pic.type && pic.data ? `data:${pic.type};base64,${pic.data}` : null;
                const samAccountName = msg.from.split("@")[0];
                handleNotification(
                    samAccountName,
                    fullName,
                    imageUrl,
                    nickName,
                    chat_section,
                    "CHAT"
                );
              }).catch((err) => {
                handleNotification(
                    null,
                    "User",
                    null,
                    "U",
                    chat_section,
                    "CHAT"
                );
              });
            } else {
              handleNotification(
                  null,
                  "User",
                  null,
                  "U",
                  chat_section,
                  "CHAT"
              );
            }
          }
        }; 
        
        if (msg.body) {
          if (msg.type === "groupchat") {
            const loggedUserJid = `${username}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`.toString().toLowerCase();
            let fromUserId = msg.from.split("/")[1]
            if (fromUserId === loggedUserJid) {
              return;
            }
            fromUserId = `${msg.from.split("/")[1]}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`.toString().toLowerCase();
            if (fromUserId === msg.to.split("/")[0]){
              return
            }
            flashTaskbar();
            addUnreadMessageToRoom(fromJid, {
              message: msg.body,
              from: fromUserId,
              date: moment().valueOf(),
            });
            if (fromJid !== msg.to) {
              if (!getmuteList || !getmuteList?.includes(fromJid)){
                playNotificationSound();
                if ((currentLocation + "@conference.cogencis.com".toString().toLowerCase() !== fromJid.toString().toLowerCase()) || !document.hasFocus()) {
                  await processVCard(fromJid, "Groups");
                }
              }
              if (currentLocation + "@conference.cogencis.com".toString().toLowerCase() === fromJid.toString().toLowerCase()) {
                addToChatRoomMessages({
                  date: moment().valueOf(),
                  message: msg.body,   
                  toemail: msg.to,
                  fromemail: fromUserId,
                  attachment: null,
                });
                await xmppClient.sendMessage({
                  to: msg.from.split("/")[0],
                  id: msg.id,
                  type: "groupchat",
                  receipt: "received",
                });
              }
            }
          }
          else {
            //TODO REVIEW
            if(msg?.type ==="error") return;
            flashTaskbar();
            
            const samAccountName = msg.from.split("@")[0];
            addUnreadMsg(fromJid, {
              message: msg.body,
              from: fromJid,
              date: moment().valueOf(),
            },1);


            const {contactsList = [], setContactsList} = useContactListStore.getState();
            const {rosters = [], handleUpdateRoster,} = useLoginStore.getState();
            
            console.log("contactsList--->CL---->", contactsList)
            console.log("contactsList--->Ro---->", rosters)
            console.log("contactsList--->FJID-->", fromJid)
            /*validate if Need to add to roster*/
            const isJidInRoster = filterInArrayOfObject(rosters , fromJid, 'jid').length > 0;
            console.log("contactsList--->isInRL-->", isJidInRoster)
            if (!isJidInRoster) {
              //if not in Roster update the roster
              handleUpdateRoster(fromJid);
            }

            const isJidInContact = filterInArrayOfObject(contactsList, fromJid.split('@')[0], 'samAccountName').length > 0;
            console.log("contactsList--->isInCL-->", isJidInContact)
            if (!isJidInContact){
              //if not in Contacts update the contacts
              //https://connectdevel.cogencis.com/connect/api/v1/getuserdetail?userName=C123456
              const memberDataResponse = await api.get(getAPiEndpoint("getUserDetails", `${fromJid.split("@")[0]}`));
              if(memberDataResponse && memberDataResponse?.data && memberDataResponse?.data?.data && memberDataResponse?.data?.data?.length >0){
                const memberDetails = memberDataResponse?.data?.data[0];
                console.log("contactsList--->memberDetails--->", memberDetails);
                memberDetails["message"] = ""
                memberDetails["unreadCount"] = 1
                memberDetails["sentDate"] = new Date();
                contactsList.push(memberDetails)
                setContactsList(contactsList);
                delay(2000);
              }
            }
            
            
            
            
            updateContactList(samAccountName + "@cogencis.com", msg.body);
            console.log("getmuteList-->", getmuteList)
            // if (fromJid !== msg.to && !getmuteList.includes(fromJid)) {
            if (fromJid !== msg.to) {
              
              if (!getmuteList || !getmuteList?.includes(fromJid)){
                playNotificationSound();
                if ((chatOpenId !== fromJid) || !document.hasFocus()) {
                  await processVCard(fromJid, "Chat");
                }
              }
              
              if (chatOpenId === fromJid) {
                addToMessages({
                  date: moment().valueOf(),
                  message: msg.body,
                  toemail: msg.to,
                  fromemail: msg.from.split("/")[0],
                  attachment: null,
                });

                await xmppClient.sendMessage({
                  to: msg.from.split("/")[0],
                  id: msg.id,
                  type: "chat",
                  receipt: "received",
                });
                // to show notification if I am on another tab
                // if (!document.hasFocus()){
                //   await processVCard(fromJid, "Chat");
                // }
              }
            }
          }
        }
      });

      xmppClient.on("presence", async (presence) => {
        console.log("xmpp-presence-1->", presence)
        
        /** if presence from ad to are same them and logged user is also same them 
        send presence available fro logged in user id to handle multiple login presence */
        if (!presence?.from){
          return;
        }
        
        /*If new user subscribed then refresh the presence presence*/
        const loggedUserJid = `${username}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`.toString().toLowerCase();
        if (presence && presence?.from 
            && presence?.to 
            && presence?.type 
            && presence?.type === "subscribed"
            && presence?.to.toString().toLowerCase() === loggedUserJid){
          console.log("Update subscribed presence");
          setTimeout(()=>{
            xmppClient.sendPresence({
              status: "online",
              show: "chat",
              priority: 1,
            });
          }, 1000)
          return
        }

        /** if presence from ad to are same them and logged user is also same them
         send presence available fro logged in user id to handle multiple login presence */
        /**
         {
            "from": "suryakant.cogtest@cogencis.com/mobile-1733722625075",
            "lang": "",
            "to": "suryakant.cogtest@cogencis.com/web-1733722660122",
            "priority": 0,
            "type": "unavailable"
        }
         * */
        /*Handling multiple Presence Start*/
        // if(presence && presence?.from && presence?.to && presence?.type && presence?.type === 'unavailable'){
        //   let presenceFrom = presence?.from.split("@")[0].toString().toLowerCase();
        //   let presenceTo = presence?.to.split("@")[0].toString().toLowerCase();
        //   const { userInformation } = useLoginStore.getState();
        //   const loggedUser = userInformation[0]?.samAccountName?.toLowerCase();
        //   console.log("xmpp-presence-S-loggedUser-->", loggedUser)
        //   console.log("xmpp-presence-S-presenceFrom-->", presenceFrom)
        //   console.log("xmpp-presence-S-presenceTo-->", presenceTo)
        //   if (loggedUser && presenceFrom === presenceTo && presenceFrom === loggedUser){
        //     console.log("xmpp-presence-SendLogged->", presence)
        //     xmppClient.sendPresence({
        //       status: usePresenceStore?.getState()?.myPresence || "online",
        //       show: "chat",
        //       priority: 1,
        //     });
        //   }
        // }
        /*Handling multiple Presence End*/
        
        let jid = presence?.from.split("/")[0];
        if (!jid.includes("@")) {
          jid = presence?.from.split("/")[0] + "@cogencis.com";
        }
        // console.log("xmpp-presence-2->", jid)
        const status = presence?.status?.toLowerCase();
        console.log("xmpp-presence-3->", jid, status)
        const { userInformation } = useLoginStore.getState();
        const loggedUser =
          userInformation[0]?.samAccountName?.toLowerCase() + "@cogencis.com";

        useChatStore?.getState().updatePresence(jid, status);

        if (jid === loggedUser && status !== undefined) {
          set({ availabilityStatus: status });
        }

        const lastPresenceStatus = get().lastPresenceStatus[jid] || "offline";
        if (status === lastPresenceStatus) {
          return;
        }

        if (presence.type === "available") {
          set({ onlineStatus: "available" });
        } else if (presence.type === "unavailable") {
          set({ onlineStatus: "offline" });
        }
      });
      
      xmppClient.on("presence:error", async (presence) => {
        console.log("xmpp-presence:error-1->", presence)
      });
      
      xmppClient.on("probe", async (presence) => {
        console.log("xmpp-probe-presence-1->", presence)
      });
      
      
      xmppClient.on("chat:state", async (state) => {
        set({ typingStatus: state === "composing" });
      });
 
      xmppClient.on("connected", async (state) => {
        console.log("connected-to-xxmp server");
        resolve({ message: "Connected successfully", xmppClient });
        const { xmppClient: storeXmppClint } = get();
        if (!storeXmppClint && xmppClient){
          set({ isConnected: true, onlineStatus: "online", xmppClient });
        }
        
        const getRoster = async () =>{
          try {
            let rostersList = await xmppClient?.getRoster();
            if(rostersList && rostersList?.items && rostersList?.items?.length > 0){
              rostersList.items.map((rosterItems)=>{
                console.log("rostersList--->rosterItems-->", rosterItems)
                if(rosterItems && rosterItems.subscription !== "both"){
                  xmppClient.sendPresence({
                    // from: senderJID,
                    to: rosterItems.jid,
                    type: 'subscribe',
                  });
                }
                return rosterItems
              })
            }
            console.log("rostersList--->", rostersList);
          } catch (e) {
            console.log("rostersList--->", e);
          }
        }

        setTimeout(()=>{
          getRoster();
        }, 5000)
        
        setTimeout(()=>{
          xmppClient.sendPresence({
            status: "online",
            show: "chat",
            priority: 1,
          });
        }, 3000)
        
        console.log("connected to xxmp server");
      });
      xmppClient.on("auth:failed", () => {
        console.log(`Auth failed for ${username}`);
      });

      const reconnectClient = async () => {
        try {
          console.log("xmppClient-reconnectClient-->", xmppClient)
          const {xmppClient: xmppClientReconnect} = useXmppStore.getState();
          if (xmppClientReconnect){
            // xmppClient.sendPresence({ status: 'offline' });
            // xmppClient.disconnect();
            console.log("xmppClient-Called")
            xmppClientReconnect.connect();
            console.log("Reconnected to XMPP server.");
          }else{
            console.log("Can not reconnect to XMPP server.");
          }
        } catch (error) {
          console.log("Reconnection failed:", error);
          const {xmppClient: xmppClientReconnect} = useXmppStore.getState();
          if (xmppClientReconnect){
            setTimeout(reconnectClient, 5000); // Retry after 5 seconds   
          }
        }
      };

      xmppClient.use({
        name: 'reconnect',
        plugin: () => {
          // This enables auto-reconnect on disconnect
          console.log("Reconnect-Logic")
        }
      });

      xmppClient.on('reconnecting', () => {
        console.log('Attempting to reconnect...');
      });

      xmppClient.on('reconnected', () => {
        console.log('Reconnected to server.');
      });
      
      
      xmppClient.on("disconnected", async (state) => {
        // const getStorageData=getAuthData();
        // const { connectXMPP } = useXmppStore.getState();
        // set({ isConnected: false });
        set({ isConnected: false, onlineStatus: "offline" });
        // TODO remove the alert
        // alert("User got disconnected")
        delay(2000)
        console.log("disconnected--state->", state)
        console.log("disconnected--xmppClient->", xmppClient) 
        await reconnectClient();
      });

      xmppClient.on('carbon:sent', async (args) => {
        const { currentLocation } = useXmppStore.getState();
        console.log("carbon--->:sent-args--->", args)
        //TODO Handle for non focus users
        const {from:fromUser, carbon= {}, to:toUser, type = ""}  = args;
        if (type === "chat" && carbon?.forward?.message && fromUser && toUser && fromUser === toUser.split("/")[0]){
          const {body, chatState, to: msgTo, from: msgFrom, attachment} =  carbon?.forward?.message
          console.log("carbon--->msgTo-->",  msgTo)
          console.log("carbon--->-msgFrom-->",  msgFrom)
          const currentUserJID = `${currentLocation}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`.toString().toLowerCase()
          // if (currentLocation)
          if (!chatState && body && currentUserJID === msgTo.toString().toLowerCase()){
            addToMessages({
              date: moment().valueOf(),
              message: body,
              toemail: msgTo,
              fromemail: fromUser,
              attachment: attachment || null,
            });
          }
        if (!chatState && body){
            const { updateContactList } = useContactListStore.getState();
            updateContactList(msgTo, body);
          }
        }
      })


      // For group chat (MUC)
      xmppClient.on('muc:message', (message) => {
        console.log('muc:Message received in group chat:', message);
      });

      xmppClient.on('muc:join', (participant) => {
        console.log('muc:Participant joined:', participant);
      });

      xmppClient.on('muc:leave', (participant) => {
        console.log('muc:Participant left:', participant);
      });
      
      // Other existing event handlers
      const cachedSM = sessionStorage.getItem("cachedSM");
      if (cachedSM) xmppClient.sm.load(JSON.parse(cachedSM, Utils.reviveData));
        xmppClient.connect();
    });
  },
  sendMessage: async (to, message, attachment) => {
    to = to.toLowerCase()
    const {updateContactList} = useContactListStore.getState();
    const {currentLocation} = useXmppStore.getState();
    const currentUserJID = `${currentLocation}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`.toString().toLowerCase()
    // let contactList = localStorage.getItem("contactList-store");
    // contactList = JSON.parse(contactList).state.contactsList;
    console.log("sendMessage------TO----------------->", to)
    console.log("sendMessage------currentLocation---->", currentUserJID)
    updateContactList(to, message);
    const {xmppClient} = useXmppStore.getState();
    console.log("xmppClient-sendMessage->", xmppClient)
    if (!xmppClient) {
      console.log('message->xmpp-not-connected')
      return;
    }
    if (attachment) {
      const response = await xmppClient.sendMessage({
        to,
        body: message,
        type: "chat",
        attachment: attachment,
      });
      console.log("response-->", response)
      if (currentUserJID === to) {
        set((state) => ({
          messages: [
            ...state.messages,
            {
              date: moment().valueOf(),
              message: null,
              toemail: to,
              fromemail: useLoginStore.getState().username + "@cogencis.com",
              attachment: attachment || null,
            },
          ],
        }));
      }
    } else {
      const response = await xmppClient.sendMessage({
        to,
        body: message,
        type: "chat",
      });
      console.log("response-->", response)
      if (currentUserJID === to) {
        set((state) => ({
          messages: [
            ...state.messages,
            {
              date: moment().valueOf(),
              message,
              toemail: to,
              fromemail: useLoginStore.getState().username + "@cogencis.com",
              attachment: null,
            },
          ],
        }));
      }
    }
  },

  sendMessageToRoom: async (roomJID, message) => {
    roomJID = roomJID.toLowerCase()
    const {currentLocation} = useXmppStore.getState();
    const currentRoomJID = `${currentLocation}${process.env.REACT_APP_CHAT_GROUP_DOMAIN}`.toString().toLowerCase()
    const currentUserJID = `${useLoginStore.getState().username}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`.toString().toLowerCase()
    // let contactList = localStorage.getItem("contactList-store");
    // contactList = JSON.parse(contactList).state.contactsList;
    console.log("sendMessage------TO----------------->", roomJID)
    console.log("sendMessage------currentRoomJID---->", currentRoomJID)
    console.log("sendMessage------currentUserJID---->", currentUserJID)
    const {xmppClient} = useXmppStore.getState();
    // console.log("SendMessageToRoom-->", roomJID)
    if (!xmppClient) {
      console.error("XMPP Is not Connected");
      return;
    }
    try {
      await xmppClient.sendMessage({
        to: roomJID,
        body: message,
        type: "groupchat",
      });

      if (roomJID === currentRoomJID) {
        set((state) => ({
          messages: [
            ...state.messages,
            {
              date: moment().valueOf(),
              message,
              toemail: roomJID,
              fromemail: currentUserJID,
              attachment: null,
            },
          ],
        }));
      }
    } catch (error) {
      console.error(`Failed to send message to room: ${roomJID}`, error);
    }
  },

  joinOrCreateChatroom: async (roomJid, nickname) => {
    const { xmppClient } = get();

    if (!xmppClient) {
      console.error("XMPP client is not connected");
      return;
    }
    // console.log("joinOrCreateChatroom-1", roomJid);
    // console.log("joinOrCreateChatroom-2", nickname);
    // Send a presence stanza to join the room
    setTimeout(async () => {
      const joinChatResponse = await xmppClient.sendPresence({
        to: `${roomJid}/${nickname}`, // Room JID with your desired nickname
        type: "available", // Presence type 'available' to join the room
      });
      // console.log("joinOrCreateChatroom-Response-->", joinChatResponse)
    }, 0)
  },

  configureChatRoom: async (roomJID, roomname, roomdesc) => {
    const { xmppClient } = get();
    const form = {
      type: "submit",
      fields: [
        { var: "muc#roomconfig_roomname", value: roomname },
        { var: "muc#roomconfig_roomdesc", value: roomdesc },
        { var: "muc#roomconfig_persistentroom", value: "1" }, // Make room persistent
        { var: "muc#roomconfig_membersonly", value: "0" }, // Not a members-only room
        { var: "muc#roomconfig_publicroom", value: "1" }, // List room publicly
      ],
    };

    xmppClient
      .sendIQ({
        to: roomJID,
        type: "set",
        id: "config-room-id",
        muc: {
          owner: {
            x: form,
          },
        },
      })
      .then((result) => {
        console.log("Room configuration completed", result);
      })
      .catch((err) => {
        console.error("Failed to configure room", err);
      });
  },

  setMessages: (payload) => {
    return set((state) => ({ messages: [...state.messages, payload] }));
  },
  initMessages: (payload) => {
    return set((state) => ({ messages: payload }));
  },

  setTypingState: async (to, state) => {
    const { xmppClient } = useXmppStore.getState();
    if (!xmppClient) {
      console.error("XMPP client is not connected");
      return;
    }
    to = to?.toString().toLowerCase();
    await xmppClient.sendMessage({
      to: to,
      type: "chat",
      chatState: state,
    });
  },

  setUserSubscribe: async (senderJID, receiverJId) => {
    const { xmppClient } = useXmppStore.getState();
    if (!xmppClient) {
      console.error("XMPP client is not connected");
      return;
    }
    console.log("JID-R->", receiverJId)
    console.log("JID-S->", senderJID)
    // Send a subscription request to the contact
    xmppClient.sendPresence({
      // from: senderJID,
      to: receiverJId,
      type: 'subscribe',
    });

    xmppClient.sendPresence({
      from: receiverJId,
      to: senderJID,
      type: 'subscribed',
    });
    
    // xmppClient.sendPresence({
    //   to: senderJID,
    //   type: 'subscribe', // Subscribe to their presence
    // })
    
    // console.log("responseSender-->", responseSender);
    // console.log("responseReceiver-->", responseReceiver);
    return { };
  },

  setPresence: async (status) => {
    const { xmppClient } = get();
    if (!xmppClient) {
      console.error("XMPP client is not connected");
      return;
    }
    await xmppClient.sendPresence({ status });
  },
  setXmppClient: async (payload) => {
    const { xmppClient } = get();
    set({
      xmppClient: null,
      isConnected: false,
      availabilityStatus: "offline",
      onlineStatus: "offline",
      messages: [],
      typingStatus: false,
      isMessagesLoading: null,
      pageNo: 1,
      scrollToBottom: true,
      hasMore: true,
      areTyping: [],
      userCredentials: {},
      lastPresenceStatus: {},
      isFlashing: false,
      currentLocation: null,
    })
    setTimeout(async () => {
      try {
        await xmppClient?.disableCarbons()
      } catch (e) {
      }
      try {
        xmppClient?.disconnect();
      } catch (e) {
      }
    }, 3000)
  },
  resetXmppStore: () => set({
    xmppClient: null,
    isConnected: false,
    availabilityStatus: "online",
    onlineStatus: "offline",
    messages: [],
    typingStatus: false,
    isMessagesLoading: null,
    pageNo: 1,
    scrollToBottom: true,
    hasMore: true,
    areTyping: [],
    userCredentials: {},
    lastPresenceStatus: {},
    isFlashing: false,
    currentLocation: null,
  }),
}));

export default useXmppStore;
