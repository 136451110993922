

// import { create } from "zustand";

// // Utility function to remove a key from an object
// const removeKey = (obj, theKey) =>
//   Object.fromEntries(Object.entries(obj).filter(([key]) => key !== theKey));

// const useUnreadMessageStore = create((set) => ({
//   unreadMsg: {},  // Stores unread messages by JID
  
//   // Add a new unread message for a particular JID
//   addUnreadMsg: (jid, message) =>
//     set((state) => ({
//       unreadMsg: {
//         ...state.unreadMsg,
//         [jid]: [...(state.unreadMsg[jid] || []), message],  // Append new message
//       },
//     })),

//   // Mark messages as read for a particular JID (e.g., when a chat is opened)
//   handleMsgRead: (jid) =>
//     set((state) => {
//       const currentUnread = state.unreadMsg[jid];
//       // If there are no messages for the JID, return the current state without changes
//       if (!currentUnread || currentUnread.length === 0) {
//         // console.log("No unread messages for JID:", jid);
//         return state;
//       }

//       // Reset unread messages for this JID
//       // console.log(`Resetting unread messages for JID: ${jid}`);
//       return {
//         unreadMsg: {
//           ...state.unreadMsg,
//           [jid]: [],  // Clear messages for this JID
//         },
//       };
//     }),
    
//   // Mark the last message in a conversation as unread (useful for manual marking)
//   markAsUnread: (jid, lastMessage) =>
//     set((state) => ({
//       unreadMsg: {
//         ...state.unreadMsg,
//         [jid]: [lastMessage],  // Set the last message as unread
//       },
//     })),
    
//   // Remove the entire record for a JID (if needed)
//   removeUnreadForJID: (jid) =>
//     set((state) => ({
//       unreadMsg: removeKey(state.unreadMsg, jid),  // Remove unread messages for the JID
//     })),
// }));

// export default useUnreadMessageStore;

import { create } from "zustand";

// Utility function to remove a key from an object
const removeKey = (obj, theKey) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => key !== theKey));

const useUnreadMessageStore = create((set) => ({
  unreadMsg: {}, 
  addUnreadMsg: (jid, message, count) =>
    set((state) => {
      const existingData = state?.unreadMsg[jid] || { messages: [], count: 0 };
      return {
        unreadMsg: {
          ...state.unreadMsg,
          [jid]: {
            messages: [message], // Append new message 
            count: existingData?.count + count,
          },
        },
      };
    }),

    addMultipleUnreadMsgs: (jid, message, count) =>
    set((state) => {
      return {
        unreadMsg: {
          ...state.unreadMsg,
          [jid]: {
            messages: [message], 
            count:count,
          },
        },
      };
    }),
    
  handleMsgRead: (jid) =>
    set((state) => {
      const currentUnread = state?.unreadMsg[jid];
      // console.log(currentUnread?.count,"currentUnread",jid);
      
      if (currentUnread?.count === 0|| currentUnread?.messages?.length === 0) {
        return state; // No unread messages for this JID
      }
      else{
      return {
        unreadMsg: {
          ...state?.unreadMsg,
          [jid]: {
            messages: [], // Clear messages for this JID
            count: 0, // Reset count to 0
          },
        },
      };
    }
    }),

  // Mark the last message in a conversation as unread
  markAsUnread: (jid, lastMessage) =>
    set((state) => ({
      unreadMsg: {
        ...state.unreadMsg,
        [jid]: {
          messages: [lastMessage], // Set the last message as unread
          count: 1, // Reset count to 1
        },
      },
    })),

  // Remove the entire record for a JID
  removeUnreadForJID: (jid) =>
    set((state) => ({
      unreadMsg: removeKey(state.unreadMsg, jid), // Remove unread messages for the JID
    })),
    resetUnreadStore: () => set({
        unreadMsg: {},
    }),
}));

export default useUnreadMessageStore;
