import {
  Checkbox,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AttachmentBtn from "../../../../../common/components/attachment-btn";
import ConferenceInviteBtn from "../../../../../common/components/conference-invite-btn";
import ScreenCaptureBtn from "../../../../../common/components/screen-capture-btn";
import { getAPiEndpoint } from "../../../../../config/apiEndpoints";
import api from "../../../../../services/axiosInterceptor";
import { getInitials } from "../../../../../services/dataService";
import useChatStore from "../../../../../store/chatStore";
import useLoginStore from "../../../../../store/loginStore";
import useConfigStore from "../../../../../store/settingsStore";
import useUnreadMessageStore from "../../../../../store/unreadMessageStore";
import useXmppStore from "../../../../../store/xmppStore";
import ChatProfile from "../chat-profile";
import useSettingModel from "../../../../settings/viewModel/useSettingModel";
import {
  deleteUserFromRoster,
  updateSettingsPost,
} from "../../../../../services/useApiService";
import axios from "axios";
import AttachmentPreview from "../../../../../common/components/attachment-preview";
import Search from "../../../../../common/components/search";
import useChatModel from "../../../viewModel/useChatModel";
import { yellow } from "@mui/material/colors";
import getDeviceType from "../../../../../services/deviceTypeService";
import useContactListStore from "../../../../../store/contactList";
import Modal from "../../../../../common/components/modal";

const highlightText = (text, searchText, index, currentIndex, searchRefs) => {
  // console.log(`Highlighting text: ${text} for searchText: ${searchText} at index: ${index} currentIndex: ${currentIndex}`);

  if (!searchText) return text; // Return original text if no search text

  const regex = new RegExp(`(${searchText})`, "gi");
  const parts = text.split(regex);

  return parts
    .map((part, idx) => {
      const isMatch = regex.test(part);
      const isActive = index === currentIndex;

      if (isMatch) {
        // Log when highlighting
        // console.log(`Highlighting part: ${part} at index: ${index}, isActive: ${isActive}`);
        return `<span
          class="highlight ${isActive ? "active" : ""}" 
          style="background: ${isActive ? "orange" : "yellow"}">
          ${part}
        </span>`;
      }
      return part;
    })
    .join("");
};

const getFileIcon = (fileType) => {
  const fileIcons = {
    pdf: "icon-pdf",
    doc: "icon-doc",
    docx: "icon-docx",
    jpg: "icon-jpg",
    jpeg: "icon-jpeg",
    png: "icon-png",
    xls: "icon-xls",
    xlsx: "icon-xlsx",
    csv: "icon-csv",
    txt: "icon-txt",
  };
  const fallbackIcon = "icon-file";
  return fileIcons[fileType] !== undefined ? fileIcons[fileType] : fallbackIcon;
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["bytes", "kb", "mb", "gb", "tb"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const fileSize = (bytes / Math.pow(k, i)).toFixed(2);
  return `${fileSize} ${sizes[i]}`;
};

const escapeHTML = (message) => {
  return message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const useFetchChatHistory = (state, logedUser, chatContainerRef) => {
  const pageNo = useXmppStore((state) => state.pageNo);
  const addMessages = useXmppStore((state) => state.addMessages);
  const isMessagesLoading = useXmppStore((state) => state.isMessagesLoading);
  const setIsMessagesLoading = useXmppStore(
    (state) => state.setIsMessagesLoading
  );
  const hasMore = useXmppStore((state) => state.hasMore);
  const setHasMore = useXmppStore((state) => state.setHasMore);
  // const  setRefetchChatList  = useXmppStore((state) => state.setRefetchChatList);
  const fetchChatHistory = async () => {
    try {
      const chatContainer = chatContainerRef.current;
      const scrollHeightBeforeLoad = chatContainer?.scrollHeight || 0;
      const scrollTopBeforeLoad = chatContainer?.scrollTop || 0;

      setIsMessagesLoading(true);
      const response = await api.get(
        getAPiEndpoint(
          "chatHistory",
          `${logedUser}&pageNo=${pageNo}&pageSize=20&toId=${state?.samAccountName}@cogencis.com`
        )
      );
      setIsMessagesLoading(false);
      if (response.data.success && response.data.data[0]) {
        const data = JSON.parse(response.data.data[0]?.data);
        if (data.length > 0) {
          addMessages(data);
          if (chatContainer) {
            setTimeout(() => {
              const scrollHeightAfterLoad = chatContainer?.scrollHeight || 0;
              chatContainer.scrollTop =
                scrollTopBeforeLoad +
                (scrollHeightAfterLoad - scrollHeightBeforeLoad);
            }, 0);
          }
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      setIsMessagesLoading(false);
    }
  };
  useEffect(() => {
    if (isMessagesLoading || !hasMore) return;

    fetchChatHistory();
    // setRefetchChatList(false);
  }, [state?.samAccountName, pageNo]);

  return {
    fetchChatHistory,
  };
};

// const useScrollTriggers = (
//   chatContainerRef,
//   messages,
//   loading,
//   hasMore,
//   pageNo,
//   setPageNo,
//   scrollToBottom,
//   setScrollToBottom,
//   setShowScrollIcon
// ) => {
//   useEffect(() => {
//     if (chatContainerRef.current && scrollToBottom) {
//       chatContainerRef.current.scrollTop =
//         chatContainerRef.current.scrollHeight;
//     }
//   }, [messages]);

//   useEffect(() => {
//     const chatContainer = chatContainerRef.current;
//     const handleScroll = () => {
//       if (chatContainer.scrollTop == 0) {
//         setPageNo(pageNo + 1);
//         setScrollToBottom(false);
//         setShowScrollIcon(true)
//       }
//     };
//     if (chatContainer) {
//       chatContainer.addEventListener("scroll", handleScroll);
//       return () => {
//         chatContainer.removeEventListener("scroll", handleScroll);
//       };
//     }
//   }, [loading, hasMore,setShowScrollIcon]);
// };

const useScrollTriggers = (
  chatContainerRef,
  messages,
  loading,
  hasMore,
  pageNo,
  setPageNo,
  scrollToBottom,
  setScrollToBottom,
  setShowScrollIcon
) => {
  useEffect(() => {
    if (chatContainerRef.current && scrollToBottom) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    const handleScroll = () => {
      const isAtTop = chatContainer.scrollTop === 0;
      const isAtBottom =
        chatContainer.scrollHeight - chatContainer.scrollTop <=
        chatContainer.clientHeight + 5;
      const isScrollable =
        chatContainer.scrollHeight > chatContainer.clientHeight;
      if (isScrollable) {
        if (isAtTop && hasMore) {
          setPageNo(pageNo + 1);
          setScrollToBottom(false);
          setShowScrollIcon(true);
        } else if (isAtBottom) {
          setScrollToBottom(true);
          setShowScrollIcon(false);
        } else {
          setScrollToBottom(false);
          setShowScrollIcon(true);
        }
      } else {
        setShowScrollIcon(false);
      }
    };

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loading, hasMore, pageNo, setShowScrollIcon, setScrollToBottom]);
};

const groupMessagesByDate = (messages) => {
  return messages.reduce((acc, message) => {
    const date = moment(message.date).format("YYYY-MM-DD");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});
};

function Details() {
  const { state } = useLocation();
  let {
    messages,
    isMessagesLoading: loading,
    pageNo,
    hasMore,
    setPageNo,
    scrollToBottom,
    setScrollToBottom,
    sendMessage,
    setTypingState,
    areTyping,
    setLocation,
  } = useXmppStore();
  const { fetchUsers, fetchFileType } = useChatModel();
  const { updateSettings } = useSettingModel();
  const { username, userInformation, token, rosters, handleRemoveRoster } = useLoginStore();
  const [isMuted, setIsMuted] = useState(false);
  const logedUser = username + "@cogencis.com";
  const { handleMsgRead, markAsUnread } = useUnreadMessageStore();
  const setContactsList = useContactListStore((state) => state.setContactsList);
  const contactsList = useContactListStore((state) => state.contactsList);
  const [isMobile, setIsMobile] = useState(false);

  const chatContainerRef = useRef(null);
  const screenShort = useRef(null);
  const captured = useRef(null);
  const moreMenuRef = useRef(null);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [isShowDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [isDeleteLoader, setDeleteLoader] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalMatches, setTotalMatches] = useState(0);
  const searchRefs = useRef([]);

  const { clearSerchVal } = useChatStore();
  const inputRef = useRef(null);
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  // console.log(showScrollIcon,"showScrollIcon");

  const navigate = useNavigate(); // Initialize the useNavigate hook
  const lastMessage = messages[messages.length - 1]; // Get the last message in the conversation
  useFetchChatHistory(state, logedUser, chatContainerRef);
  messages = messages.sort((a, b) => a.date - b.date);
  messages = groupMessagesByDate(messages);

  useScrollTriggers(
    chatContainerRef,
    messages,
    loading,
    hasMore,
    pageNo,
    setPageNo,
    scrollToBottom,
    setScrollToBottom,
    setShowScrollIcon
  );
  // const { showPreview, setShowPreview } = useChatModel();
  const [inputMessage, setInputMessage] = useState("");
  const [inputMessageForAttachment, setInputMessageForAttachment] =
    useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [menuVisible, setMenuVisible] = useState(null);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [iscaptured, setIsCaptured] = useState(false);
  const isUserTyping = useRef(false)

  useEffect(() => {
    if (inputMessage && !isUserTyping?.current){
      isUserTyping.current = true;
      setTypingState(`${state?.samAccountName}@cogencis.com`, "composing");
    }
    const timeoutId = setTimeout(() => {
      if (isUserTyping?.current){
        isUserTyping.current = false;
        setTypingState(`${state?.samAccountName}@cogencis.com`, "paused");  
      }
    }, 2000); 
    return () => {
      clearTimeout(timeoutId)
    };
  }, [inputMessage]);
  

  const sendInputMessage = async (to, msg) => {
    const message = inputMessage || msg;
    if (!message) {
      console.error("Cannot send an empty message or file");
      return;
    }
    if (message?.trim()) {
      /*Code added to update Chat list start*/
      // const contactLists = state.contactsList;
      let findIndex = contactsList.findIndex(
        (items) => items?.samAccountName === state?.samAccountName
      );
      setTimeout(() => {
        if (findIndex === -1 && state?.samAccountName) {
          // let tempItem = {...state, ...{message: msg, sentDate: new Date().getTime()}}
          // let updatedContactList = [...[tempItem], ...contactsList];
          // setContactsList(updatedContactList);
          /*Added timeout so that the contact is updated and updated list is shown after api fetch as ai fetch takes time.*/
          setTimeout(() => {
            fetchUsers();
          }, 1500);
        }
      }, 0);
      /*Code added to update Chat list End*/

      await sendMessage(to + "@cogencis.com", message?.trim());
      setShowPreview(false);
      setInputMessageForAttachment("");
      setInputMessage("");
      clearSerchVal("");
      setSelectedFile([]);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  
  useEffect(() => {
    setLocation(state.samAccountName)
    return () => {
      setLocation(null)
    };
  }, [state.samAccountName]);

  useEffect(() => {
    return () => {
      try {
        if (isUserTyping?.current) {
          isUserTyping.current = false
          setTypingState(`${state?.samAccountName}@cogencis.com`, "paused");
        }
      } catch (e) {
      }
      setInputMessage("");
      setInputMessageForAttachment("");
      setShowPreview(false);
    };
  }, [state?.samAccountName]);

  const handleScrollDown = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setScrollToBottom(true); // Enable auto-scroll again
      setShowScrollIcon(false); // Hide the scroll icon
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const presence = useChatStore();
  const showOnline =
    presence?.presence[state?.samAccountName?.toLowerCase() + "@cogencis.com"];
  const { config, setMuteUserList, removeFromMuteUserList, clearMuteUserList } =
    useConfigStore();
  const getmuteList = config.muteUserList;

  const handleToggleMute = async () => {
    const email = `${state?.samAccountName?.toLowerCase()}@cogencis.com`;
    const newMuteState = !isMuted;
    let updatedConfig = { ...config };
    if (newMuteState) {
      setMuteUserList(email);
      updatedConfig = {
        ...config,
        muteUserList: [...config.muteUserList, email],
      };
    } else {
      removeFromMuteUserList(email);
      updatedConfig = {
        ...config,
        muteUserList: config.muteUserList.filter((user) => user !== email),
      };
    }
    setIsMuted(newMuteState);
    setTimeout(() => {
      setShowMoreMenu(false);
    }, 300);
    await updateSettingsPost(email, updatedConfig, token);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
        setShowMoreMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreMenuRef]);

  const chatOpenId = `${window.location.pathname
    .split("/")
    .pop()
    ?.toLowerCase()}@cogencis.com`;

  useEffect(() => {
    setShowMoreMenu(false);
  }, [chatOpenId]);

  const setRefs = (node) => {
    //screenShort.current = node;
    chatContainerRef.current = node;
  };

  const setScreenRefs = (node) => {
    screenShort.current = node;
    captured.current = node;
  };

  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const current = `${state?.samAccountName?.toLowerCase()}@cogencis.com`;
    const findCurrentTying = areTyping?.find((ele) => ele.from === current);
    if (findCurrentTying) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  }, [areTyping]);

  const handleMarkAsUnread = () => {
    const currentJid = state.samAccountName?.toLowerCase() + "@cogencis.com";
    markAsUnread(currentJid, lastMessage);
    useChatStore.getState().setMarkedUnreadChat(currentJid, true);
    navigate("/chat");
  };

  const handleDeleteUser = async () => {
    try {
      setDeleteLoader(true);
      const jid = state.samAccountName?.toLowerCase() + "@cogencis.com";
      const response = await deleteUserFromRoster(userInformation, jid);
      await handleRemoveRoster(jid);
      navigate("/chat");
    } catch (e) {
    } finally {
      setDeleteLoader(false);
      setShowDeleteConfirmationModal(false);
      try {
        fetchUsers();
      } catch (e) {}
      console.log("rosters-->", rosters);
    }
  };

  const handleFileSelect = async (files) => {
    setSelectedFile(Array.from(files));
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setInputMessageForAttachment("");
    setInputMessage("");
  };

  const handleSendAttachment = async () => {
    try {
      for (const file of selectedFile) {
        const formData = new FormData();
        formData.append(
          "to",
          state.samAccountName.toLowerCase() + "@cogencis.com"
        );
        formData.append("from", logedUser.toLowerCase());
        formData.append("type", 0);
        formData.append("file", file);

        const response = await axios.post(
          getAPiEndpoint("uploadattachment"),
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        const fileMetadata = {
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: data?.data?.filename,
          size: file.size,
          type: file.type,
          message: inputMessageForAttachment,
        };
        const stringifiedMetadata = JSON.stringify(fileMetadata);
        sendInputMessage(state.samAccountName, stringifiedMetadata);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const handleAttachmentClick = async (ele) => {
    try {
      const response = await api.get(getAPiEndpoint("fetchattachment"), {
        params: {
          to: state.samAccountName.toLowerCase() + "@cogencis.com",
          from: logedUser.toLowerCase(),
          type: 0,
          fileName: ele.name,
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", ele.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching attachment:", error);
    }
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  function isPlainTextMessage(str) {
    try {
      const parsed = JSON.parse(str);
      return typeof parsed !== "object" || parsed === null;
    } catch (e) {
      return true;
    }
  }
  //const searchRef = useRef(null);

  // const handleClickOutside = (event) => {
  //   console.log(searchRef,"searchRef");

  //   if (searchRef.current && !searchRef.current.contains(event.target)) {
  //     setShowSearch(false);
  //     //setSearchText("");
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleClear = () => {
    setSearchText("");
  };

  // const handleSearch = (text) => {
  //   setSearchText(text);
  // };
  const oneDayBackDate = new Date(Date.now() - 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];
  const currentDate = new Date().toISOString().split("T")[0];

  const handleSearch = useCallback(
    (text) => {
      setSearchText(text);

      if (!text) {
        setTotalMatches(0);
        setCurrentIndex(0);
        return;
      }

      // Filter messages directly within the handler
      const messagesForDate = messages[currentDate] || [];
      const results = messagesForDate.filter((msg) =>
        msg.message.toLowerCase().includes(text.toLowerCase())
      );

      setTotalMatches(results.length);
      setCurrentIndex(0); // Reset to the first result on new search
    },
    [messages, currentDate]
  );

  const scrollToMatch = () => {
    if (searchRefs.current[currentIndex]) {
      searchRefs.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const goToNextMatch = () => {
    if (totalMatches > 0) {
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % totalMatches;
        console.log(`Moving to next match, newIndex: ${newIndex}`);
        scrollToMatch(); // Scroll to the new match
        return newIndex;
      });
    }
  };

  // Navigate to the previous match
  const goToPreviousMatch = () => {
    if (totalMatches > 0) {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex === 0 ? totalMatches - 1 : prevIndex - 1;
        scrollToMatch();
        return newIndex;
      });
    }
  };

  const clearSearch = () => {
    setSearchText("");
    setCurrentIndex(0);
    setTotalMatches(0);
  };

  const handleCloseSearch = () => {
    setShowSearch(!showSearch);
    setShowMoreMenu(false);
  };

  const handleMenuToggle = (id) => {
    setMenuVisible(menuVisible === id ? null : id);
  };

  // const handleDeleteMessage = () => {
  //   setShowCheckbox(true);
  // };

  const handleSendMessageButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Refocus the TextField to keep the keyboard open
    if (inputRef.current) {
      inputRef.current.focus();
    }
    sendInputMessage(state?.samAccountName);
    setScrollToBottom(true);
  };

  return (
    <>
      <div className="right-wrap" ref={setScreenRefs}>
        <section>
          <div className="bs-page-head ">
            <ChatProfile
              showOnline={showOnline}
              isTyping={isTyping}
              iscaptured={iscaptured}
            />
            <div className="cta-wrap cm-hidden">
              <ul className="list">
                {/* <FontBtn
                  scale={fontSize}
                  handleInc={handleInc}
                  handleDec={handleDec}
                /> */}
                <ScreenCaptureBtn
                  setIsCaptured={setIsCaptured}
                  iscaptured={iscaptured}
                  ref={screenShort}
                />
                <AttachmentBtn onFileUpload={handleFileSelect} />
                {/* <ConferenceInviteBtn /> */}
              </ul>
            </div>
            <button
              className="btn-more"
              onClick={() => {
                setShowMoreMenu(!showMoreMenu);
              }}
            >
              <i className="icon more"></i>
            </button>
            {showMoreMenu && (
              <div className="menu-overlay">
                <div ref={moreMenuRef} className="more-menu-content">
                  <ul className="list">
                    <li
                      className="item"
                      onClick={handleToggleMute}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="mod-icon-text">
                        {(() => {
                          const isMuted = getmuteList?.includes(
                            `${state?.samAccountName?.toLowerCase()}@cogencis.com`
                          );
                          return (
                            <>
                              <i
                                className={`icon ${
                                  isMuted ? "mute" : "unmute"
                                }`}
                                style={{ pointerEvents: "none" }}
                              ></i>
                              <p
                                className="text"
                                style={{ pointerEvents: "none" }}
                              >
                                {isMuted
                                  ? "Unmute Conversation"
                                  : "Mute Conversation"}
                              </p>
                            </>
                          );
                        })()}
                      </div>
                    </li>

                    <li className="item" onClick={handleMarkAsUnread}>
                      <div className="mod-icon-text">
                        <i className="icon unread"></i>
                        <p className="text">Mark as unread</p>
                      </div>
                    </li>
                    {/* TODO  commented fOr 7th Event // Due to error */}
                  {/*  <li
                      className="item"
                      onClick={() => {
                        setShowSearch(!showSearch);
                        setShowMoreMenu(false);
                      }}
                    >
                      <div className="mod-icon-text">
                        <i className="icon search-filled"></i>
                        <p className="text">Search Conversation</p>
                      </div>
                    </li>*/}
                    <li
                        style={{display:"none"}}
                      className="item"
                      onClick={() => {
                        setShowDeleteConfirmationModal(true);
                        setShowMoreMenu(false);
                      }}
                    >
                      <div className="mod-icon-text">
                        <i className="icon delete"></i>
                        <p className="text">Delete Conversation 1</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </section>
        <section>
          <div className="bs-section typ-main">
            <div className="bs-chat">
              {showPreview ? (
                <AttachmentPreview
                  selectedFile={selectedFile}
                  onClose={handleClosePreview}
                  inputMessageForAttachment={inputMessageForAttachment}
                  setInputMessageForAttachment={setInputMessageForAttachment}
                  handleSendAttachment={handleSendAttachment}
                  setScrollToBottom={setScrollToBottom}
                />
              ) : (
                <>
                  <div className="message-group">
                    <div className="chat-wrap" ref={setRefs}>
                      {showSearch && (
                        <span className="searchbar">
                          <Search
                            searchText={searchText}
                            handleClear={handleClear}
                            handleSearch={handleSearch}
                            // searchCount={filteredMessages}
                          />
                          <div className="tool-wrap">
                            <p className="count-value">
                              {totalMatches > 0
                                ? `${currentIndex + 1}/${totalMatches}`
                                : "0/0"}
                            </p>
                            <span className="more-search">
                              <i
                                className="icon down reverse"
                                onClick={goToNextMatch}
                              ></i>
                              <i
                                className="icon down"
                                onClick={goToPreviousMatch}
                              ></i>
                            </span>
                            <i
                              className="icon icon-close"
                              onClick={handleCloseSearch}
                            ></i>
                          </div>
                        </span>
                      )}
                      {loading && (
                        <div className="chat-loading">
                          Loading more messages...
                        </div>
                      )}
                      {messages &&
                        Object.keys(messages).map((date) => (
                          <div className="chat-group" key={date}>
                            <div className="date-header">
                              {moment(date).format("MMMM Do, YYYY")}
                            </div>
                            <ul className="list">
                              {messages[date].map((ele, id) => {
                                const isSelected = selectedMessages.includes(
                                  ele.id
                                );
                                return (
                                  <li
                                    key={id}
                                    className={
                                      ele?.fromemail?.toLowerCase() ===
                                      logedUser?.toLowerCase()
                                        ? "item send"
                                        : ele?.fromemail?.toLowerCase() ===
                                          `${state?.samAccountName}@cogencis.com`.toLowerCase()
                                        ? "item receive"
                                        : "item send"
                                    }
                                  >
                                    {showCheckbox && (
                                      <div className="checkbox-wrap">
                                        <Checkbox
                                          checked={isSelected}
                                          onChange={() => {
                                            if (isSelected) {
                                              setSelectedMessages(
                                                selectedMessages.filter(
                                                  (messageId) =>
                                                    messageId !== ele.id
                                                )
                                              );
                                            } else {
                                              setSelectedMessages([
                                                ...selectedMessages,
                                                ele.id,
                                              ]);
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                    {false && (
                                      <div className="img-wrap">
                                        {ele?.fromemail?.toLowerCase() ===
                                        `${state?.samAccountName}@cogencis.com`?.toLowerCase() ? (
                                          state?.thumbnailPhoto ? (
                                            <img
                                              src={`data:image/png;base64,${state.thumbnailPhoto}`}
                                              alt="profile"
                                            />
                                          ) : (
                                            <span className="img-text">
                                              {getInitials(state?.name)}
                                            </span>
                                          )
                                        ) : userInformation[0]
                                            ?.thumbnailPhoto ? (
                                          <img
                                            src={`data:image/png;base64,${userInformation[0].thumbnailPhoto}`}
                                            alt="profile"
                                          />
                                        ) : (
                                          <span className="img-text">
                                            {getInitials(
                                              userInformation[0].name
                                            )}
                                          </span>
                                        )}
                                      </div>
                                    )}

                                    {/* <div className="wrap">
                                    {typeof ele?.message === "object" ? (
                                      <div className="wrap typ-attachment">
                                        <span className="attachment-card">
                                          <i className="icon icon-file filled"></i>
                                          <div className="card-details-wrap">
                                            <div className="title-wrap">
                                              <h4 className="c-title">
                                                {ele.message?.name ||
                                                  "File Name"}
                                              </h4>
                                            </div>
                                            <div className="details-wrap">
                                              <span className="d-text">
                                                {ele.message?.size || "0 KB"}
                                              </span>
                                              <span className="d-text">
                                                {ele.message?.type || "pdf"}
                                              </span>
                                            </div>
                                          </div>
                                          <i className="icon download"></i>
                                        </span>
                                        <span className="time">
                                          {moment(
                                            ele?.date || ele?.time
                                          ).format("hh:mm A")}
                                        </span>
                                      </div>
                                    ) : (
                                      <p
                                        className="text"
                                        style={{ fontSize: `${16}px` }}
                                        dangerouslySetInnerHTML={{
                                          __html: ele?.message?.replace(
                                            /\n/g,
                                            "<br/>"
                                          ),
                                        }}
                                      ></p>
                                    )}
                                    <span className="time">
                                      {moment(ele?.date || ele?.time).format(
                                        "hh:mm A"
                                      )}
                                    </span>
                                  </div> */}
                                    <div
                                      // className={`wrap ${
                                      //   typeof ele?.message === "string" &&
                                      //   isJsonString(ele.message)
                                      //     ? "typ-attachment"
                                      //     : ""
                                      // }`}
                                      className={`wrap ${
                                        !isPlainTextMessage(ele?.message)
                                          ? "typ-attachment"
                                          : ""
                                      }`}
                                    >
                                      {/* <span
                                        className="chat-dropdown"
                                        onClick={() => handleMenuToggle(id)}
                                      >
                                        <i className="icon down"></i>
                                      </span>
                                      {menuVisible === id && (
                                        <>
                                          <div className="chat-dropdown-menu">
                                            <ul className="menu-list">
                                              <li
                                                className="menu-item"
                                                onClick={() =>
                                                  setShowCheckbox(
                                                    (prev) => !prev
                                                  )
                                                }
                                              >
                                                Delete Message
                                              </li>
                                            </ul>
                                          </div>
                                        </>
                                      )} */}
                                      {typeof ele?.message === "string"
                                        ? (() => {
                                            try {
                                              const parsedMessage = JSON.parse(
                                                ele.message
                                              );
                                              {
                                                /* const isImage =
                                                parsedMessage?.type.startsWith("image/"); */
                                              }
                                              const fileExtension =
                                                parsedMessage?.type
                                                  .split("/")
                                                  .pop()
                                                  .toLowerCase();

                                              return (
                                                <>
                                                  <span className="attachment-card">
                                                    {false ? (
                                                      <img
                                                        src={URL.createObjectURL(
                                                          new Blob([
                                                            parsedMessage.file,
                                                          ])
                                                        )}
                                                        alt={
                                                          parsedMessage?.name ||
                                                          "Image"
                                                        }
                                                        className="attachment-image"
                                                      />
                                                    ) : (
                                                      <i
                                                        className={`icon ${getFileIcon(
                                                          fileExtension
                                                        )}`}
                                                      ></i>
                                                    )}
                                                    <div className="card-details-wrap">
                                                      <div className="title-wrap">
                                                        <h4 className="c-title">
                                                          {parsedMessage?.name ||
                                                            `${
                                                              parsedMessage.type.split(
                                                                "/"
                                                              )[1]
                                                            } file`}
                                                        </h4>
                                                      </div>
                                                      <div className="details-wrap">
                                                        <span className="d-text">
                                                          {formatFileSize(
                                                            parsedMessage?.size
                                                          )}
                                                        </span>
                                                        <span className="d-text">
                                                          {parsedMessage.type.includes(
                                                            "/"
                                                          )
                                                            ? parsedMessage.type.split(
                                                                "/"
                                                              )[1]
                                                            : null}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <i
                                                      className="icon download"
                                                      onClick={() =>
                                                        handleAttachmentClick(
                                                          parsedMessage
                                                        )
                                                      }
                                                    ></i>
                                                  </span>
                                                  {parsedMessage?.message && (
                                                    <p
                                                      className="text"
                                                      // dangerouslySetInnerHTML={{
                                                      //   __html:
                                                      //     ele?.message?.replace(
                                                      //       /\n/g,
                                                      //       "<br/>"
                                                      //     ),
                                                      // }}
                                                    >
                                                      {" "}
                                                      {parsedMessage?.message}
                                                    </p>
                                                  )}
                                                </>
                                              );
                                            } catch (error) {
                                              // console.error("Failed to parse message:", error);
                                              return (
                                                // <>
                                                //   <p
                                                //     className="text"
                                                //     dangerouslySetInnerHTML={{
                                                //       __html:
                                                //         ele?.message?.replace(
                                                //           /\n/g,
                                                //           "<br/>"
                                                //         ),
                                                //     }}
                                                //   ></p>
                                                // </>
                                                <>
                                                  <p
                                                    key={id}
                                                    className="text"
                                                    dangerouslySetInnerHTML={{
                                                      __html: highlightText(
                                                        escapeHTML(
                                                          ele?.message
                                                        ).replace(
                                                          /\n/g,
                                                          "<br/>"
                                                        ),
                                                        searchText, // Pass in your search text
                                                        id,
                                                        currentIndex,
                                                        searchRefs
                                                      ),
                                                    }}
                                                  ></p>
                                                </>
                                              );
                                            }
                                          })()
                                        : ""}
                                      <span className="time">
                                        {moment(ele?.date || ele?.time).format(
                                          "hh:mm A"
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ))}
                    </div>
                    <div className="input-group">
                      {showScrollIcon && (
                        <span
                          className="scroll-down"
                          onClick={handleScrollDown}
                        >
                          <i className="icon scroll-down"></i>
                        </span>
                      )}
                      {!showCheckbox ? (
                        <>
                          <div className="message-wrap">
                            <FormControl variant="standard">
                              <TextField
                                id="message"
                                ref={inputRef}
                                multiline
                                maxRows={4}
                                value={inputMessage}
                                onKeyDown={(e) => {
                                  if (
                                    getDeviceType() === "web" &&
                                    e.key === "Enter" &&
                                    !e.shiftKey
                                  ) {
                                    e.preventDefault(); // Prevents the default newline behavior
                                    sendInputMessage(state?.samAccountName);
                                    setScrollToBottom(true);
                                  }
                                }}
                                onChange={(e) => {
                                  setInputMessage(e.target.value);
                                  // setTypingState(
                                  //   `${state?.samAccountName}@cogencis.com`,
                                  //   "composing"
                                  // );
                                  // setTimeout(() => {
                                  //   setTypingState(
                                  //     `${state?.samAccountName}@cogencis.com`,
                                  //     "paused"
                                  //   );
                                  // }, 1000);
                                }}
                                placeholder="Type here..."
                                name="message"
                                // InputProps={{
                                //   startAdornment: (
                                //     <React.Fragment>
                                //       {showEmojiPicker && (
                                //         <Picker onEmojiClick={handleEmojiSelect} />
                                //       )}
                                //       <button onClick={toggleEmojiPicker}>😀</button>
                                //     </React.Fragment>
                                //   ),
                                // }}
                              />
                            </FormControl>
                          </div>
                          <ul className="cta-list">
                            <ScreenCaptureBtn ref={screenShort} />
                            <AttachmentBtn onFileUpload={handleFileSelect} />
                          </ul>
                          <button type="button">
                            <i
                              onTouchStart={handleSendMessageButtonClick}
                              onMouseDown={handleSendMessageButtonClick}
                              className="icon message-send"
                              // onClick={(event) => handleSendMessageButtonClick(event)}
                            />
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="delete-wrap">
                            <span className="d-content">
                              <i className="icon icon-close"></i>
                              <p className="d-text">3 selected</p>
                            </span>
                            <button className="delete-btn" type="button">
                              <i className="icon delete"></i>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      {isShowDeleteConfirmationModal && (
        <div className="bs-modal">
          <div
            className={`modal-overlay ${isMobile ? "typ-drawer" : ""}`}
            onClick={() => setShowDeleteConfirmationModal(false)}
          >
            <div
              className={`${
                isMobile ? "lyt-drawer" : "modal-content typ-page"
              }`}
            >
              <div className="delete-wrap">
                <p className="title">Are you sure you want to Delete?</p>
                <div className="cta-wrap">
                  <button
                    onClick={() => handleDeleteUser()}
                    disabled={isDeleteLoader}
                    className="bs-btn btn-solid"
                  >
                    {isDeleteLoader ? <CircularProgress size={14} /> : "Yes"}
                  </button>
                  <button
                    className="bs-btn btn-border"
                    onClick={() => setShowDeleteConfirmationModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Details;
