//const baseUrl = "https://marketwatch.cogencis.com/staging/connect/api/v1"
//const tempUrl = "http://testchat.cogencis.com:5002/api/v1"

export const paths = {
    login: process.env.REACT_APP_BASE_URL + "/login",
    reValidate: process.env.REACT_APP_BASE_AUTH_URL + "/revalidate?terminalID=",
    loginAuth: process.env.REACT_APP_BASE_AUTH_URL + "/login",
    getmyconnections:process.env.REACT_APP_BASE_URL + "/getmyconnections?userName=",
    getUsers: process.env.REACT_APP_BASE_URL + "/get?ep=users",
    getUsersRecent: process.env.REACT_APP_BASE_URL  + "/getrecentcharts?fromId=",
    getGroups: process.env.REACT_APP_BASE_URL + "/get?ep=groups",
     getChatRooms: process.env.REACT_APP_BASE_URL + "/get?ep=chatrooms",
    getChatRoomsList: process.env.REACT_APP_BASE_URL + "/getChatrooms?userName=",
    deleteChatRoom: process.env.REACT_APP_BASE_URL + "/delete?ep=chatrooms",
    deleteUserFromChatRoom: process.env.REACT_APP_BASE_URL + "/delete?ep=chatrooms",
    addUserToChatRoom: process.env.REACT_APP_BASE_URL + "/post?ep=chatrooms",
    createChatRoom: process.env.REACT_APP_BASE_URL + "/post?ep=chatrooms",
    updateChatRoom: process.env.REACT_APP_BASE_URL + "/PUT?ep=chatrooms",
    getSearchUser: process.env.REACT_APP_BASE_URL  +"/searchuser?userName=",
    updateUser: process.env.REACT_APP_BASE_URL  +"/updateuser", ///profile update in setting
    getUserDetails: process.env.REACT_APP_BASE_URL  +"/getuserdetail?userName=",
    chatHistory: process.env.REACT_APP_BASE_URL + '/getrecentcharts?fromId=',
    chatroomsHistory: process.env.REACT_APP_BASE_URL + '/getrecentcharts?',
    addRoster: process.env.REACT_APP_BASE_URL + '/post?ep=users/',
    updateSettings: process.env.REACT_APP_BASE_URL +'/updatesettings',
    getSettings:process.env.REACT_APP_BASE_URL +"/getdefaultsettings?jid=",
   connectRM: process.env.REACT_APP_BASE_URL + "/getrmdetails?terminalId=",
   getAllfaqs:  process.env.REACT_APP_BASE_URL + "/getfaqs",
   getActiveQueries: process.env.REACT_APP_BASE_URL + "/getqueries?terminalId=",
   postRaiseQuery: process.env.REACT_APP_BASE_URL+ "/raisedquery",
   changepassword: process.env.REACT_APP_BASE_URL+"/changepassword",
   regenerateOtp: process.env.REACT_APP_BASE_URL+"/regenerate-otp",
   forgotpassword :process.env.REACT_APP_BASE_URL+"/forgotpassword",
   resetpassword: process.env.REACT_APP_BASE_URL+"/resetpassword",
   uploadattachment :process.env.REACT_APP_BASE_URL+ "/uploadattachment",
   fetchattachment :process.env.REACT_APP_BASE_URL+ "/fetchattachment",
   fileType:process.env.REACT_APP_BASE_URL+ "/getconfig?ou=",
   markallmessagesread:process.env.REACT_APP_BASE_URL+"/markallmessagesread",
   deleteUserFromRoster:process.env.REACT_APP_BASE_URL+"/delete?ep=",
   searchMessages:process.env.REACT_APP_BASE_URL+"/searchMessages?"
}


export const getAPiEndpoint = (apiName, query = "") => {
    let apiPath = paths[apiName];
    return apiPath + query;
};

export const getEndpoint = (
    apiName,
    pathVars
) => {
    const apiPath = paths[apiName];
    const joinParams = pathVars.map((item) => {
        return `${item.pathName}=${item.pathValue}`;
    });

    return `${apiPath}?${joinParams.join("&")}`;
};
