import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileDetails from "../../../../../common/components/profile-details";
import { getInitials } from "../../../../../services/dataService";
import useSettingModel from "../../../../settings/viewModel/useSettingModel";

export default function ChatProfile({ showOnline, isTyping, iscaptured }) {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/chat");
  };
  const { config } = useSettingModel();
  // console.log('config',config?.notification?.Show_Typing_Indicator);

  const { state } = useLocation();
  const [openProfile, setOpenProfile] = useState(false);
  const [userName, setUserName] = useState("");

  const handleProfileView = (name) => {
    setUserName(name);
    setOpenProfile(true);
  };
  const handleClose = () => {
    setOpenProfile(false);
  };

  return (
    <>
      <div className="action-wrap cm-visible" onClick={handleGoBack}>
        <i className="back"></i>
      </div>
      <div className="mod-logo-text">
        <div
          className="img-wrap"
          onClick={() => handleProfileView(state?.samAccountName)}
        >
          {state?.thumbnailPhoto ? (
            <img
              src={`data:image/png;base64,${state?.thumbnailPhoto}`}
              alt="profile"
            />
          ) : (
            <span className="img-text">{getInitials(state?.name)}</span>
          )}
          {/* <span className="badge"></span>  */}
        </div>
        <div className="head-wrap">
          <h4 className="head-title">{state?.name}</h4>
          {/* <img src={`http://testchat.cogencis.com:9090/plugins/presence/status?jid=${state?.samAccountName}@cogencis.com`} /> */}

          <>
            <div className="status-wrap">
              <span
                className={`${
                  showOnline ? `badge-icon ${showOnline}` : "badge grey"
                }`}
              ></span>
              <span className="badge-status">
                {/* {isTyping ? "Typing..." : showOnline || "Offline"} */}
                {config?.notification?.Show_Typing_Indicator
                  ? isTyping
                    ? "Typing..."
                    : showOnline || "Offline"
                  : showOnline || "Offline"}
              </span>
            </div>
          </>
        </div>
      </div>
      {openProfile && (
        <div className="bs-modal">
          <div className="modal-overlay" onClick={handleClose}>
            <div className="modal-content right-wrap full-page">
              <ProfileDetails
                handleClose={handleClose}
                name={userName}
                showOnline={showOnline}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
