import { FormControl, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useLocation } from "react-router-dom";

export default function AttachmentPreview({
  selectedFile,
  onClose,
  inputMessageForAttachment,
  setInputMessageForAttachment,
  handleSendAttachment,
  setScrollToBottom
}) {
  
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const isMultiple = selectedFile.length > 1;
  const { state } = useLocation();
  const renderFilePreview = (file) => {
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{ maxWidth: "100%", maxHeight: "300px" }}
        />
      );
    } else if (file.type === "application/pdf") {
      return (
        <embed
          src={URL.createObjectURL(file)}
          type="application/pdf"
          width="100%"
          height="300px"
          title={file.name}
        />
      );
    } else {
      return <p>Preview not available for this file type: {file.name}</p>;
    }
  };

  return (
    <>
      <div className="bs-chat-preview">
        <div className="action-wrap">
          <span className="cm-visible" onClick={onClose}>
            <i className="icon icon-back"></i>
          </span>
          <span className="cm-hidden" onClick={onClose}>
            <i className="icon icon-close"></i>
          </span>
          <h4 className="title">
            {!isMultiple ? selectedFile[0].name : "Attachments"}
          </h4>
        </div>
        <div className="content-wrap">
          {isMultiple ? (
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              centeredSlides={true}
              navigation={true}
              slidesPerView={1}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mainSwiper"
            >
              {selectedFile.map((file, index) => (
                <SwiperSlide key={index}>
                  {/* {file.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                  ) : (
                    <img src="pdf_placeholder.png" alt="PDF file" />
                  )} */}
                  {file.type.startsWith("image/") ? (
                    <div className="preview-img">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                      />
                    </div>
                  ) : file.type === "application/pdf" ? (
                    <embed
                      src={URL.createObjectURL(file)}
                      type="application/pdf"
                      width="100%"
                      height="300px"
                      title={file.name}
                    />
                  ) : (
                    <div className="title-wrap">
                      <p className="title">
                        Preview not available for this file type: {file.name}
                      </p>
                    </div>
                  )}
                  <div className="title-wrap">
                    <h4 className="title">{file.name}</h4>
                  </div>
                  <div className="details-wrap">
                    <span className="size-text">
                      {(file.size / 1024).toFixed(2)} KB
                    </span>
                    <span className="type-text">{file.type.split("/")[1]}</span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <>
              <div className="preview-img">
                <img
                  src={URL.createObjectURL(selectedFile[0])}
                  alt={selectedFile[0].name}
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
              </div>
              <div className="title-wrap">
                <h4 className="title">{selectedFile[0].name}</h4>
              </div>
              <div className="details-wrap">
                <span className="size-text">
                  {(selectedFile[0].size / 1024).toFixed(2)} KB
                </span>
                <span className="type-text">
                  {selectedFile[0].type.split("/")[1]}
                </span>
              </div>
            </>
          )}
          {isMultiple && (
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="thumbnail"
            >
              {selectedFile.map((file, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{ width: "100%", height: "auto" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div className="cta-wrap">
          <div className="input-group">
            <div className="message-wrap">
              <FormControl variant="standard">
                <TextField
                  id="message"
                  multiline
                  maxRows={4}
                  placeholder="Type here..."
                  name="message"
                  value={inputMessageForAttachment}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevents the default newline behavior
                      handleSendAttachment();
                      setScrollToBottom(true);
                    }
                  }}
                  onChange={(e) => setInputMessageForAttachment(e.target.value)}
                />
              </FormControl>
            </div>
            <button type="button" onClick={handleSendAttachment}>
              <i className="icon message-send"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
