import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useContactListStore = create(
  persist(
    (set) => ({
      contactsList: [],
      contactRosterList:[],
      setContactsList: (items) => set({ contactsList: items || [] }),
      chatRoomList: [],
      setChatRoomList: (items) => set({ chatRoomList: items || []}),
      setRosterList: (items) => set({ contactRosterList: items || []}),
      updateContactList: (to, msg) => {
        set((state) => {
          const updatedContacts = state.contactsList.map((user) =>
            user?.samAccountName?.toLowerCase() + "@cogencis.com" ===
            to.toLowerCase()
              ? { ...user, message: msg, sentDate: new Date().getTime() }
              : user
          );
          const sortedContacts = updatedContacts.sort(
            (a, b) => b.sentDate - a.sentDate
          );
          return { contactsList: sortedContacts };
        });
      },
        resetContactListStore: () => set({
            contactsList: [],
            contactRosterList:[],
            chatRoomList: [],
        }),
    }),
    {
      name: "contactList-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useContactListStore;
