import React from "react";

export default function ConferenceInviteBtn() {
  return (
    <>
      <li className="item">
        <button>
          <i className="icon invite-conference"></i>
          <span className="cta-text">invite to conference</span>
        </button>
      </li>
    </>
  );
}
