// import axios from "axios";
// import { getAPiEndpoint } from "../config/apiEndpoints";
// import useLoginStore from "../store/loginStore";


// const reLoginService = async () => {
//   const handleUserDetails = useLoginStore.getState().handleUserDetails;
//   const password = userState.password;
//   const data = {
//     username: userState.userName,
//     password: password,
//   };
//   try {
//     const response = await axios.post(getAPiEndpoint("login"), data);
//     const token = response.data.token;

//     handleUserDetails(
//       token,
//       data.username,
//       response.data.userdetails,
//       data.password
//     );

//     // Store token and expiry in localStorage
//     // localStorage.setItem(
//     //   "login-store",
//     //   JSON.stringify({
//     //     state: { ...userState, token, expiryTime },
//     //   })
//     // );
//   } catch (error) {
//     console.error(error)
//   }
// };

// export default reLoginService;

import axios from "axios";
import {getAPiEndpoint} from "../config/apiEndpoints";
import useLoginStore from "../store/loginStore";
import {clearAuthData, getAuthData, setAuthData} from '../services/auth';
import useXmppStore from "../store/xmppStore";
import useChatDetailsStore from "../store/chatDetails";
import useChatStore from "../store/chatStore";
import useContactListStore from "../store/contactList";
import useGroupchatStore from "../store/groupchatStore";
import useConfigStore from "../store/settingsStore";
import useUnreadChatRoomStore from "../store/unreadChatRoomStore";
import useUnreadMessageStore from "../store/unreadMessageStore";

const reLoginService = async () => {
    const handleUserDetails = useLoginStore.getState().handleUserDetails;
    const setXmppClient = useXmppStore.getState().setXmppClient;
    const setPresence = useXmppStore.getState().setPresence;
    const setUserCredentials = useXmppStore.getState().setUserCredentials;
    const clearTokens = useLoginStore.getState().clearTokens;
    const xmppClient = useXmppStore.getState().xmppClient;
    const getCookieData = getAuthData();

    const resetAllStores = () => {
        try {
            useChatDetailsStore.getState().resetChatDetailsStore();
            useChatStore.getState().resetChatStore();
            useContactListStore.getState().resetContactListStore();
            useGroupchatStore.getState().resetGroupChatStore();
            useLoginStore.getState().resetLoginStore();
            useConfigStore.getState().resetSettingStore();
            useUnreadChatRoomStore.getState().resetUnreadChatRoomStore();
            useUnreadMessageStore.getState().resetUnreadStore();
            useXmppStore.getState().resetXmppStore();
        } catch (e) {
        }
    };

    const logoutUserAndNavigateToLoginScreen = () => {
        try {
            xmppClient?.disconnect();
        } catch (e) {
        }
        clearTokens();
        setPresence("");
        // await xmppClient.sendPresence("", "chat", 0);
        setXmppClient(null);
        setUserCredentials({});
        localStorage.clear();
        sessionStorage.clear();
        clearAuthData()
        resetAllStores()
        window.location.reload();
    }

    // Check if we have necessary information (username, password, and refreshToken)
    if (!getCookieData.userName || !getCookieData.refreshToken) {
        console.error("Missing necessary information to refresh login.");
        return;
    }
    try {
        // Send a request to the login endpoint to refresh the token
        const response_Auth = await axios.get(getAPiEndpoint("reValidate", `${getCookieData.userName}&refreshToken=${getCookieData.refreshToken}`));
        if (response_Auth?.data?.entitlements?.hasOwnProperty("chat")) {
            clearAuthData();
            const response = await axios.get(
                getAPiEndpoint(
                    "getUserDetails",
                    getCookieData.userName
                ),
                {headers: {Authorization: `Bearer ${response_Auth?.data?.token}`}}
            );
            const userDetails = response?.data?.data;
            console.log(userDetails, "userDetails");

            handleUserDetails(response_Auth?.data?.token, getCookieData.userName, userDetails);
            const authData = {
                token: response_Auth?.data?.token,
                expiry: response_Auth?.data?.expiry,
                refreshToken: response_Auth?.data?.refreshToken,
                userName: response?.data?.data[0]?.samAccountName,
            };
            setAuthData(authData);
        } else {
            logoutUserAndNavigateToLoginScreen()
        }
    } catch (error) {
        logoutUserAndNavigateToLoginScreen()
    }finally {
        console.log("Re-login-Attempt-completed")
    }
};

export default reLoginService;
