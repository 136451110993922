// import React, { useState,useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { getInitials } from "../../../services/dataService";
// import api from "../../../services/axiosInterceptor";
// import { getAPiEndpoint } from "../../../config/apiEndpoints";
// import NewChatroomList from "../../../module/chatroom-page/view/components/new-chatroom";
// import useLoginStore from "../../../store/loginStore";
// import useXmppStore from "../../../store/xmppStore";

// function ChatroomDetails({ onClose }) {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const details = location.state;
//   const [addNew, setAddNew] = useState(false);
//   const xmppClient = useXmppStore((state) => state.xmppClient);
//   const [participantNames, setParticipantNames] = useState([]);
//   const loggedUserData = useLoginStore();
//   let loggedUserInformation = loggedUserData.userInformation
//   // let loggedUsername = useLoginStore((state) => state.username);

//   // console.log('loggedUserData', ...loggedUserInformation)

//   const handleDeleteGroup = async () => {
//     const response = await api.delete(
//       getAPiEndpoint("deleteChatRoom", `/${details.roomName}`)
//     );
//     console.log("handleDeleteGroup", response);
//     navigate("/chatroom");
//   };
//   const handleExitGroup = async () => {
//     const response = await api.delete(
//       getAPiEndpoint("deleteChatRoom", `/${details.roomName}`)
//     );
//     console.log("handleDeleteGroup", response);
//     navigate("/chatroom");
//   };

//   const handleDeleteUser = async (jid) => {
//     const response = await api.delete(
//       getAPiEndpoint(
//         "deleteUserFromChatRoom",
//         `/${details.roomName}/members/${jid}`
//       )
//     );
//     console.log("handleDeleteUser", response);
//     navigate("/chatroom");
//   };

//   const handleAddUser = (event) => {
//     event.stopPropagation();
//     setAddNew(true);
//   };

//   const closeAddUser = () => {
//     setAddNew(false);
//   };

//   console.log(details,"details");
//   useEffect(() => {
//     const fetchParticipantNames = async () => {

//         const chatRoomParticipants = [...details.owners, ...details.members];
//         const participantPromises = chatRoomParticipants.map(jid =>
//             xmppClient.getVCard(jid).then(vCard => vCard?.fullName || "Unknown Name")
//         );
//         try {
//             const names = await Promise.all(participantPromises);
//             setParticipantNames(names);
//         } catch (error) {
//             console.error("Error fetching participant names:", error);
//         }
//     };

//     fetchParticipantNames();
// }, [details, xmppClient]);

//   return (
//     <>
//       <div className="bs-profile-details typ-group">
//         <div className="action-wrap">
//           <i className="icon back" onClick={onClose}></i>
//         </div>
//         <div className="heading-wrap">
//           Chatroom
//           <i className="icon close" onClick={onClose}></i>
//         </div>
//         <div className="profile-wrap">
//           <div className="mod-logo-text">
//             <div className="img-wrap">
//               <span>{getInitials(details.roomName)}</span>
//             </div>
//             <div className="head-wrap">
//               <h4 className="title">{details.roomName}</h4>
//               <p className="subtitle"></p>
//               <ul className="details-list">
//                 <li className="details-item">
//                   <span>Chatroom</span>
//                 </li>
//                 <li className="details-item">
//                   <span>total members: {details.members.length + 1}</span>
//                 </li>
//               </ul>
//             </div>
//             <span onClick={handleAddUser}>
//               <i className="icon requests"></i>
//             </span>
//           </div>
//         </div>
//         <div className="details-wrap">
//           <strong className="title">About</strong>
//           <p className="desc">{details.description || "-"}</p>
//         </div>
//         <div className="contact-details typ-group">
//           {addNew ? (
//             <div className="user-wrap">
//               <NewChatroomList
//                 existingGroup={true}
//                 closeModal={closeAddUser}
//                 details={details}
//               />
//             </div>
//           ) : (
//             <>
//               <ul className="c-list">
//                 {participantNames?.length > 0 ? (
//                   participantNames?.map((item) => (
//                     <li className="item" key={item}>
//                       <div className="mod-logo-text">
//                         <div className="img-wrap">
//                           <span>{getInitials(item)}</span>
//                         </div>
//                         <div className="head-wrap">
//                           <h4 className="title">{item}</h4>
//                           <p className="">
//                             {/* {details.owners.map((owner) =>
//                               owner === item ? "Owner" : "Member"
//                             )} */}
//                             {console.log(details.owners.includes(item),details.owners,"owner",item)}
//                             {details.owners.includes(item)
//   ? "Owner"
//   : "Member"// Default role if `item` is not found in either array
// }
//                           </p>
//                         </div>
//                         { details.owners[0] === loggedUserInformation[0].samAccountName.toLowerCase() + '@cogencis.com' &&
//                         details.owners.map((owner) =>
//                           owner !== item ? (
//                             <span onClick={() => handleDeleteUser(item)}>
//                               <i className="icon delete red"></i>
//                             </span>
//                           ) : (
//                             <></>
//                           )
//                         )}
//                       </div>
//                     </li>
//                   ))
//                 ) : (
//                   <li className="item no-members">
//                     <div className="mod-logo-text">
//                       <h4 className="title">No members</h4>
//                     </div>
//                   </li>
//                 )}
//               </ul>
//             </>
//           )}
//         </div>
//         <div className="group-details">
//           <div className="cta-wrap typ-red">
//             <button onClick={()=>{
//               details.owners[0] === loggedUserInformation[0].samAccountName.toLowerCase() + '@cogencis.com' ?
//               handleDeleteGroup():
//               handleDeleteUser()
//             }}>
//               <i className="icon logout red"></i>
//               <span>{details.owners[0] === loggedUserInformation[0].samAccountName.toLowerCase() + '@cogencis.com' ? 'Delete Group' : 'Exit Group'}</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ChatroomDetails;
import {
  Checkbox,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getInitials } from "../../../services/dataService";
import api from "../../../services/axiosInterceptor";
import { getAPiEndpoint } from "../../../config/apiEndpoints";
import NewChatroomList from "../../../module/chatroom-page/view/components/new-chatroom";
import useLoginStore from "../../../store/loginStore";
import useXmppStore from "../../../store/xmppStore";
import { toast, ToastContainer } from "react-toastify";
import useContactListStore from "../../../store/contactList";
import Modal from "../modal";

function ChatroomDetails({ onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const details = location.state;
  const { chatRoomList, setChatRoomList } = useContactListStore();
  const [isShowDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [isDeleteLoader, setDeleteLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [addNew, setAddNew] = useState(false);
  const [groupImage, setGroupImage] = useState(details?.subject || null);
  const xmppClient = useXmppStore((state) => state.xmppClient);
  const [participantNames, setParticipantNames] = useState([]);
  const loggedUserData = useLoginStore();
  const loggedUserJid =
    loggedUserData.userInformation[0].samAccountName.toLowerCase() +
    "@cogencis.com";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDeleteGroup = async () => {
    setDeleteLoader(true);
    try {
      const { members = [], owners = [] } = details || { members: [] };
      if (members.length <= 0) {
        const response = await api.delete(
          getAPiEndpoint("deleteChatRoom", `/${details.roomName}`)
        );
        console.log("handleDeleteGroup", response);
        navigate("/group", { state: { refresh: true } });
      } else {
        let payload = {
          owners: members.splice(0, 1),
          members: members,
        };
        updateChatRoomDetails(payload, true);
      }
    } catch (e) {
    } finally {
      setDeleteLoader(false);
    }
  };

  const handleExitGroup = async () => {
    setDeleteLoader(true);
    try {
      const response = await api.delete(
        getAPiEndpoint(
          "deleteUserFromChatRoom",
          `/${details.roomName}/members/${loggedUserJid}`
        )
      );
      console.log("handleDeleteUser", response);
      navigate("/group", { state: { refresh: true } });
    } catch (e) {
    } finally {
      setDeleteLoader(false);
    }
  };
  const handleDeleteUser = async (jid) => {
    try {
      const response = await api.delete(
        getAPiEndpoint(
          "deleteUserFromChatRoom",
          `/${details.roomName}/members/${jid}`
        )
      );
      console.log("handleDeleteUser", response);
      let tempParticipants = JSON.parse(JSON.stringify(participantNames));
      const index = tempParticipants?.findIndex(
        (item) => item?.jid?.toLowerCase().trim() === jid
      );
      if (index !== -1) {
        tempParticipants.splice(index, 1);
        setParticipantNames(tempParticipants);
      }
    } catch (e) {}
    // navigate("/chatroom", { state: { refresh: true } });
  };

  const updateChatRoomDetails = async (payload, refresh) => {
    const updatePayload = { ...details, ...payload };
    const apiUrl = getAPiEndpoint("updateChatRoom", `/${details.roomName}`);
    const response = await api.put(apiUrl, updatePayload);
    if (response.status !== 200) {
      toast.error(response?.data?.message || "Invalid Request");
      return;
    }
    /*To update chat list */
    try {
      let index = chatRoomList.findIndex(
        (item) => item.roomName === details.roomName
      );
      if (index !== -1) {
        chatRoomList[index] = updatePayload;
        setChatRoomList(chatRoomList);
      }
    } catch (e) {
      toast.error("Failed to Update");
    } finally {
      if (refresh) {
        navigate("/group", { state: { refresh: true } });
      } else {
        navigate(`/group/${details.naturalName}`, { state: updatePayload });
      }
    }
  };

  const handleImageChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      // Check the file type
      const fileType = file.type;
      if (!["image/jpeg", "image/png"].includes(fileType)) {
        toast.error("Please upload a JPEG or PNG image.");
        return;
      }

      // Check the file size (should be less than 100 kB)
      if (file.size > 100 * 1024) {
        toast.error("Image size should be less than 100 kB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target.result;
        // Create an image element to check the dimensions
        console.log("result-->", result);
        const img = new Image();
        img.src = result;
        img.onload = async () => {
          // Commented for now for resting purpose
          // if (img.width !== 96 || img.height !== 96) {
          //   toast.info("Image dimensions should be 96x96 pixels.");
          //   return;
          // }
          setGroupImage(reader.result); // Image is stored as base 64
          await updateChatRoomDetails({ subject: reader.result }, false);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddUser = (event) => {
    event.stopPropagation();
    setAddNew(true);
  };

  const closeAddUser = () => {
    setAddNew(false);
  };

  useEffect(() => {
    const fetchParticipantNames = async () => {
      const chatRoomParticipants = [...details.owners, ...details.members];
      const participantPromises = chatRoomParticipants.map((jid) =>
        xmppClient.getVCard(jid).then((vCard) => ({
          jid: jid,
          name: vCard?.fullName || "Unknown Name",
        }))
      );

      try {
        const participants = await Promise.all(participantPromises);
        setParticipantNames(participants);
      } catch (error) {
        console.error("Error fetching participant names:", error);
      }
    };

    fetchParticipantNames();
  }, [details, xmppClient]);

  return (
    <>
      <div className="bs-profile-details typ-group">
        <div className="action-wrap">
          <i className="icon back" onClick={onClose}></i>
        </div>
        <div className="heading-wrap">
          Group
          <i className="icon close" onClick={onClose}></i>
        </div>
        <div className="profile-wrap">
          <div className="mod-logo-text">
            <div
              className="img-wrap"
              onClick={() => {
                document.getElementById("groupImageInput").click();
              }}
            >
              {groupImage ? (
                <div className="group-profile">
                  <img src={groupImage} alt="Group" className="img-wrap" />
                </div>
              ) : (
                <span className="img-text">
                  {getInitials(details?.naturalName)}
                </span>
              )}
              <input
                type="file"
                id="groupImageInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
            <div className="head-wrap">
              <h4 className="title">{details.naturalName}</h4>
              <ul className="details-list">
                <li className="details-item">
                  <span>Group</span>
                </li>
                <li className="details-item">
                  <span>
                    Total members:{" "}
                    {details.members.length + details.owners.length}
                  </span>
                </li>
              </ul>
            </div>
            <span onClick={handleAddUser}>
              <i className="icon requests"></i>
            </span>
          </div>
        </div>
        <div className="details-wrap">
          <strong className="title">About</strong>
          <p className="desc">{details.description || "-"}</p>
        </div>
        <div className="contact-details typ-group">
          {addNew ? (
            <div className="user-wrap">
              <NewChatroomList
                existingGroup={true}
                closeModal={closeAddUser}
                details={details}
              />
            </div>
          ) : (
            <>
              <ul className="c-list">
                {participantNames.length > 0 ? (
                  participantNames.map((participant) => (
                    <li className="item" key={participant.jid}>
                      <div className="mod-logo-text">
                        <div className="img-wrap">
                          <span className="img-text">
                            {getInitials(participant.name)}
                          </span>
                        </div>
                        <div className="head-wrap">
                          <h4 className="title">{participant.name}</h4>
                          <p>
                            {details.owners.includes(participant.jid)
                              ? "Owner"
                              : "Member"}
                          </p>
                        </div>
                        {details.owners.includes(loggedUserJid) &&
                          !details.owners.includes(participant.jid) && (
                            <span
                              onClick={() => handleDeleteUser(participant.jid)}
                            >
                              <i className="icon delete red"></i>
                            </span>
                          )}
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="item no-members">
                    <div className="mod-logo-text">
                      <h4 className="title">No members</h4>
                    </div>
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
        <div className="group-details">
          <div className="cta-wrap typ-red">
            <button
              onClick={() => {
                setShowDeleteConfirmationModal(true);
              }}
            >
              <i className="icon logout red"></i>
              <span>
                {details.owners.includes(loggedUserJid)
                  ? "Delete Group"
                  : "Exit Group"}
              </span>
            </button>
          </div>
        </div>
      </div>
      {isShowDeleteConfirmationModal && (
        <div className="bs-modal">
          <div
            className={`modal-overlay ${isMobile ? "typ-drawer" : ""}`}
            onClick={() => setShowDeleteConfirmationModal(false)}
          >
            <div
              className={`${
                isMobile ? "lyt-drawer" : "modal-content typ-page"
              }`}
            >
              <div className="delete-wrap">
                <p className="title">{`Are you sure you want to ${
                  details.owners.includes(loggedUserJid)
                    ? "Delete Group"
                    : "Exit Group"
                }?`}</p>
                <div className="cta-wrap">
                  <button
                    onClick={() =>
                      details.owners.includes(loggedUserJid)
                        ? handleDeleteGroup()
                        : handleExitGroup()
                    }
                    disabled={isDeleteLoader}
                    className="bs-btn btn-solid"
                  >
                    {isDeleteLoader ? <CircularProgress size={14} /> : "Yes"}
                  </button>
                  <button
                    className="bs-btn btn-border"
                    onClick={() => setShowDeleteConfirmationModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <ToastContainer  autoClose={2000} /> */}
    </>
  );
}

export default ChatroomDetails;
