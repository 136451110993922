import {toast} from "react-toastify";
import {CustomToast} from "../common/components/Toaster";
import useChatStore from "../store/chatStore";

const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  return "Desktop";
};

const isNotificationSupported = () => {
  const platform = getPlatform();

  // iOS doesn't support notifications
  if (platform === "iOS") {
    return false;
  }

  // Check if it's a PWA
  const isPWA =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone ||
    document.referrer.includes("android-app://");

  // On Android, only allow notifications in PWA mode
  if (platform === "Android" && !isPWA) {
    return false;
  }

  return "Notification" in window;
};

const parsedMessages = (message) => {
  if (typeof message === "string") {
    try {
      const parsedMessage = JSON.parse(message);
      if (typeof parsedMessage === "object" && parsedMessage !== null) {
        return parsedMessage.name || parsedMessage.message || message;
      }
    } catch (e) {
      return message;
    }
  }
  return message;
};

const showToast = (title, message, icon,samAccountName) => {
  const platform = getPlatform();
  const setActiveChat = useChatStore.getState().setActiveChat;
  // Configure toast based on platform
  const toastConfig = {
    position: platform === "Desktop" ? "bottom-right" : "top-center",
    autoClose: 5000,
    hideProgressBar: platform !== "Desktop",
   // closeOnClick: true,
    pauseOnHover: platform === "Desktop",
    draggable: platform === "Desktop",
    className: platform !== "Desktop" ? "mobile-toast" : "",
    onClick :() => {
      //window.focus();
      setActiveChat({samAccountName:samAccountName});
    }
  };

  toast.info(
    <CustomToast
      title={title}
      message={message}
      imageSrc={icon}
      isMobile={platform !== "Desktop"}
      samAccountName={samAccountName}
      isReplyActive={!!samAccountName}
    />,
    toastConfig
  );
};

const handleSystemNotification = (title, message, icon) => {
  // Ensure the service worker is available
  if (navigator?.serviceWorker?.controller) {
    // toast.error("Inside-Service-Worker1");
    console.log("Inside-Service-Worker1")
    // Send a message to the service worker to show the notification
    navigator.serviceWorker.controller.postMessage({
      type: 'SHOW_NOTIFICATION',
      title: title,
      options: {
        body: message,
        icon: icon,
        // badge: '/images/badge.png',
      },
    });
  } else {
    console.log("Service worker is not available. 1")
    if ('serviceWorker' in navigator) {
      console.log("Inside-Service-Worker2")
      // toast.error("Inside-Service-Worker2");
      navigator.serviceWorker.ready
          .then((registration) => {
            if (registration.active) {
              // toast.error("Inside-Service-Worker4--> " + registration.scope);
              console.log("Inside-Service-Worker4--> " + registration.scope)
              registration.active.postMessage({
                type: 'SHOW_NOTIFICATION',
                title: title,
                options: {
                  body:  message,
                  icon: icon,
                  // badge: '/images/badge.png',
                },
              });
            } else {
              console.log('Service worker is not active.')
              // toast.error('Service worker is not active.');
            }
          })
          .catch((error) => {
            console.log('Error while waiting for service worker:')
            // toast.error('Error while waiting for service worker:', error);
          });
    } else {
      console.log("Service Worker API is not supported.")
      // toast.error('Service Worker API is not supported.');
    }
    // toast.error("Service worker is not available. 1");
  }
};


const sendNotification = async (title, body, imageUrl, nickName, samAccountName, chatType) => {
  console.log("Notification-Payloads-1->", {title, body, imageUrl, nickName, samAccountName, chatType})
  
  const setActiveChat = useChatStore.getState().setActiveChat;
  const platform = getPlatform();
  const msg = parsedMessages(body);
  const notificationSupported = isNotificationSupported();
  
  let icon =  imageUrl ? imageUrl : `https://via.placeholder.com/150?text=${nickName}`;
  if (window?.electronAPI) {
    icon = imageUrl;
  }
  
  // if (imageUrl)

  // if (document.visibilityState === "visible") {
  //   showToast(title, msg, icon,samAccountName);
  //   return;
  // }

  //TODO Validate;
  
  // if (document.hasFocus()) {
  //   showToast(title, msg, icon,samAccountName);
  //   return;
  // }
  console.log('Process-Notification-send')
  //TODO Verify and remove logs
  console.log("Notification-Payloads-2->", {
    jid: samAccountName || null,
    title: "Cogencis Connect",
    name: title || 'Default Title',
    message: msg || 'Default content',
    nickName: nickName || "U",
    type: chatType,
    icon: icon,
  })
  console.log("Notification-Payloads-p->", platform)
  if (platform === "Desktop") {
    if (window.electronAPI) {
      console.log("--electronAPI--window---->", window)
      window.electronAPI.sendNotification({
        jid: samAccountName || null,
        title: "Cogencis Connect",
        name: title || 'Default Title',
        message: msg || 'Default content',
        nickName: nickName || "U",
        type: chatType,
        icon: icon,
      });
    } else {
      console.log("Notification-Payloads-p->", Notification.permission)
      console.log("--Non-a-electronAPI--window---->", window)
      if (Notification.permission === "granted") {
        console.log("Notification-Payloads-p->", 'Send-Notification')
        const notification = new Notification(title || "New Message", {
          body: msg || "You have a new message!",
          icon: icon || "/default_icon_path.png",
        });
        notification.onclick = () => {
          window.focus();
          notification.close();
          setActiveChat({samAccountName: samAccountName});
        };
      } else if (Notification.permission !== "denied") {
        try {
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            sendNotification(title, body, icon, samAccountName, chatType);
          } else {
            // showToast(title, msg, icon, samAccountName);
          }
        } catch (error) {
          // showToast(title, msg, icon, samAccountName);
        }
      }
    }
  }

  // if (notificationSupported && platform === "Desktop") {
  //   if (Notification.permission === "granted") {
  //     if (document.hasFocus()) {
  //       const notification = new Notification(title || "New Message", {
  //         body: msg || "You have a new message!",
  //         icon: icon || "/default_icon_path.png",
  //       });
  //       notification.onclick = () => {
  //         window.focus();
  //         notification.close();
  //         setActiveChat({samAccountName:samAccountName});
  //       };
  //     }
  //     if (!document.hasFocus()){
  //       handleSystemNotification(title || "New Message", msg || "You have a new message!", icon || "/default_icon_path.png")
  //     }
  //   } else if (Notification.permission !== "denied") {
  //     try {
  //       const permission = await Notification.requestPermission();
  //       if (permission === "granted") {
  //         sendNotification(title, msg, icon);
  //       } else {
  //         showToast(title, msg, icon,samAccountName);
  //       }
  //     } catch (error) {
  //       showToast(title, msg, icon,samAccountName);
  //     }
  //   }
  // } else {
  //   showToast(title, msg, icon,samAccountName);
  // }
};

const styles = `
  .mobile-toast {
    margin: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
    width: calc(100% - env(safe-area-inset-right) - env(safe-area-inset-left));
  }
`;

// Insert styles
const styleSheet = document.createElement("style");
styleSheet.textContent = styles;
document.head.appendChild(styleSheet);

export { sendNotification };
