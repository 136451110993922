import React from "react";

export default function Search({ searchText, handleClear, handleSearch, placeholder= "Search"}) {
  return (
    <>
      <section>
        <div className="bs-search">
          <div className="input-group">
            <span className="icon icon-search"></span>
            <input
              type="text"
              placeholder={placeholder || "Search"}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {searchText && searchText.length > 0 && (
              <button
                className="icon icon-clear"
                onClick={handleClear}
              ></button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
