import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="bs-modal">
      <div className="modal-overlay typ-drawer" onClick={() => onClose()}>
        <div className="bs-drawer" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
