import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthData } from '../services/auth';

const AuthRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getCookieData=getAuthData();
  // const loginState = JSON.parse(localStorage.getItem("userData"));
  // const token = loginState?.token;
  
  useEffect(() => {
   // const isTokenExpired = loginState && Date.now() > loginState.expiryInSeconds;

    if (getCookieData?.token) {
      if (location.pathname === "/") {
        navigate("/chat");
      }
    } else {
      navigate("/");
    }
  }, [location.pathname, navigate, getCookieData?.token]);

  return null;
};

export default AuthRedirect;
