import React from "react";

function EmptyDetails() {
  return (
    <div className="right-wrap">
      <section>
        <div className="bs-page-head"></div>
      </section>
      <section>
        <div className="bs-section typ-main"></div>
      </section>
    </div>
  );
}

export default EmptyDetails;
