import React from "react";
import EmptyDetails from "../common/components/empty-details";
import ConferenceChatDetails from "../module/conference-page/view/components/conference-chat-details";
import ChatroomChatDetails from "../module/chatroom-page/view/components/chatroom-chat-details";
// import NetworkPage from "../module/network-page/view";
// import SupportPage from "../module/support-page/view";
import Details from "../module/chat-page/view/components/details";
import NewChatList from "../module/chat-page/view/components/new-chat";
import SupportChat from "../module/support-page/view/components/support";

const LoginPage = React.lazy(() => import("../module/login-page/view/index"));
const ChatPage = React.lazy(() => import("../module/chat-page/view/index"));
const ChatroomPage = React.lazy(() => import("../module/chatroom-page/view/index"));
const ConferencePage = React.lazy(() => import("../module/conference-page/view/index"));
const NetworkPage = React.lazy(() => import("../module/network-page/view/index"));
const SupportPage = React.lazy(() => import("../module/support-page/view/index"));
 //const ChatDetails = React.lazy(() => import("../module/chat-page/view/components/chat-details"));


const routes = [
    {
        path: "/",
        component: <LoginPage />,
        requiresAuth: false,
        exact: true,
    },
    {
        path: "/chat",
        component: <ChatPage />,
        requiresAuth: true,
        inner: [
            {
                path: "*",
                component: <EmptyDetails />,
                requiresAuth: true,
            },
            {
                path: ":id",
                requiresAuth: true,
                component: <Details />,
            },
        ]
    },
    {
        path: "/new-chat",
        component: <NewChatList />,
        requiresAuth: true,
    },
    {
        path: "/group",
        component: <ChatroomPage />,
        requiresAuth: true,
        inner: [
            {
                path: "*",
                component: <EmptyDetails />,
                requiresAuth: true,
            },
            {
                path: ":id",
                component: <ChatroomChatDetails />,
                requiresAuth: true,
            },
        ]
    },
    {
        path: "/conference",
        component: <ConferencePage />,
        requiresAuth: true,
        inner: [
            {
                path: "*",
                component: <EmptyDetails />,
                requiresAuth: true,
            },
            {
                path: ":id",
                component: <ConferenceChatDetails />,
                requiresAuth: true,
            },
        ]
    },
    {
        path: "/network",
        component: <NetworkPage />,
        requiresAuth: true,
        inner: [
            {
                path: "*",
                component: <EmptyDetails />,
                requiresAuth: true,
            },
            {
                path: ":id",
                component: <Details />,
                requiresAuth: true,
            },
        ]
    },
    {
        path: "/support",
        component: <SupportPage />,
        requiresAuth: true,
        inner: [
            {
                path: "*",
                component: <EmptyDetails />,
                requiresAuth: true,
            },
            {
                path: ":id",
                component: <SupportChat />,
                requiresAuth: true,
            },
        ]
    },
]

export default routes;