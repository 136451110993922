import React, { useEffect, useState } from "react";
import { useGet } from "../../../services/useApiService";
import { getAPiEndpoint } from "../../../config/apiEndpoints";
import { getInitials } from "../../../services/dataService";

function ProfileDetails({ handleClose, name }) {
  const [userInfo, setUserInfo] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const getSelectedGroupDataAction = {
    onSuccess: (data) => {
      setUserInfo(data?.data[0]);
    },
    onError: (error) => {},
  };

  const {
    data: getinfo,
    isLoading: isChatGroupsDataLoading,
    refetch: getUserDetails,
  } = useGet(
    "getUserDetails",
    getAPiEndpoint("getUserDetails", `${name}`),
    getSelectedGroupDataAction
  );

  useEffect(() => {
    getUserDetails();
  }, [name]);

  const handleCopyToClipboard = (event, text) => {
    event.preventDefault();
    setIsCopied(true);
    navigator.clipboard.writeText(text).catch((err) => {
      console.error("Failed to copy: ", err);
    });
    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };

  return (
    <>
      <div className="bs-profile-details" onClick={(e) => e.stopPropagation()}>
        <div className="action-wrap ">
          <i className="icon back" onClick={() => handleClose()}></i>
        </div>
        <div className="heading-wrap">
          Profile
          <i className="icon close" onClick={() => handleClose()}></i>
        </div>
        <div className="profile-wrap">
          <div className="mod-logo-text">
            <div className="img-wrap">
              {userInfo.thumbnailPhoto ? (
                <img
                  src={`data:image/png;base64,${userInfo.thumbnailPhoto}`}
                  alt="profile"
                />
              ) : (
                <span className="img-text">{getInitials(userInfo.name)}</span>
              )}

              {/* <span className={`badge ${showOnline ? "green" : "red"}`}></span> */}
            </div>
            <div className="head-wrap">
              <h4 className="title">{userInfo.name}</h4>
              <p className="subtitle">{userInfo.title}</p>
              <ul className="details-list">
                <li className="details-item">
                  <span>{userInfo.company}</span>
                </li>
                <li className="details-item">
                  <span>{userInfo.department}</span>
                </li>
              </ul>
            </div>
            {/* <i className="icon requests"></i> */}
          </div>
        </div>
        {/* <div className="details-wrap">
          <strong className="title">About</strong>
          <p className="desc">
            {userInfo.description ? userInfo.description : ""}
          </p>
        </div> */}
        <div className="contact-details" onClick={(e) => e.stopPropagation()}>
          <ul className="c-list">
            <li className="item">
              <a>
                <div className="mod-logo-text">
                  <i className="icon mail"></i>
                  <div className="head-wrap">
                    <h4 className="title">{userInfo.email}</h4>
                  </div>
                  <i
                    className={`icon ${isCopied ? "done" : "copy"}`}
                    onClick={(event) =>
                      handleCopyToClipboard(event, userInfo.email)
                    }
                  ></i>
                </div>
              </a>
            </li>
            <li className="item">
              <a>
                <div className="mod-logo-text">
                  <i className="icon company"></i>
                  <div className="head-wrap">
                    <h4 className="title">{userInfo.title}</h4>
                  </div>
                </div>
              </a>
            </li>
            <li className="item">
              <a>
                <div className="mod-logo-text">
                  <i className="icon telephone"></i>
                  <div className="head-wrap">
                    <h4 className="title">{userInfo.phoneNumber}</h4>
                  </div>
                </div>
              </a>
            </li>
            <li className="item">
              <a>
                <div className="mod-logo-text">
                  <i className="icon mobile"></i>
                  <div className="head-wrap">
                    <h4 className="title">{userInfo.phoneNumber}</h4>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="group-details">
              <span className="group-count-text">1 group in common</span>
              <ul className="list">
                <li className="item">
                  <div className="mod-logo-text">
                    <div className="img-wrap">
                      <img src={profile} alt="profile" />
                    </div>
                    <div className="head-wrap">
                      <h4 className="title">Finance Department</h4>
                      <p className="desc">
                        Abhay Malik, Aman Gupta, Anubhav Sharma, Bharat
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="cta-wrap">
                <button>
                  <i className="icon add-gradient"></i>
                  <span> Create group with Steve Jones </span>
                </button>
              </div>
            </div> */}
      </div>
    </>
  );
}

export default ProfileDetails;
