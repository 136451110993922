import { useEffect, useState } from "react";
import { getAPiEndpoint } from "../../../config/apiEndpoints";
import { useGet } from "../../../services/useApiService";
import useChatStore from "../../../store/chatStore";
import useContactListStore from "../../../store/contactList";
import useLoginStore from "../../../store/loginStore";

const useSearchList = () => {
  const contactsList = useContactListStore((state) => state.contactsList);
  const searchValue = useChatStore((state) => state.searchValue);
  const setSearchValue = useChatStore((state) => state.setSearchValue);
  const clearSerchVal = useChatStore((state) => state.clearSerchVal);
  const [searchListMsg, setSearchListMsg] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const { username} = useLoginStore();
  const fromId=`${username}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`

  const handleSearch = (value) => {
    setSearchValue(value);
    setSearchValue(value);
  };
  
  const handleClear = () => {
    setSearchList([]);
    setSearchValue("");
    clearSerchVal("");
  };

  const getSelectedGroupDataAction = {
    onSuccess: (data) => {
      setSearchList(data.data);
    },
    enabled: false,
  };

  const { isLoading: isSearchApiExecuting, refetch: fetchSearchUsers } = useGet(
    "getSearchUser",
    getAPiEndpoint("getSearchUser", `${searchValue}`),
    getSelectedGroupDataAction
  );

  useEffect(() => {
    if (searchValue?.length <= 0) {
      setSearchList([]);
      setSearchValue("");
      clearSerchVal("");
    }
  }, [searchValue]);
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue?.length > 2) {
        fetchSearchUsers();
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  // const getSelectedsearchDataAction = {
  //   onSuccess: (data) => {
  //     setSearchListMsg(data.data);
  //   },
  //   enabled: false,
  // };

  // const { refetch: fetchSearchMessages } = useGet(
  //   "searchMessages",
  //   getAPiEndpoint("searchMessages", `fromId=${fromId}&toId=${""}&searchText=${searchValue}&roomName=${""}`),
  //   getSelectedsearchDataAction
  // );

  // useEffect(() => {
  //   if (searchValue?.length > 0) {
  //     fetchSearchMessages();
  //   }
  // }, [searchValue]);


  return {
    searchList,
    contactsList,
    handleClear,
    handleSearch,
    setSearchList,
    searchListMsg
  };
};

export default useSearchList;
