import React from "react";
import profile from "../../../../../common/assets/images/profile.png";

export default function ConferenceProfile() {
  return (
    <>
      <div className="action-wrap">
        <i className="back"></i>
      </div>
      <div className="mod-logo-text">
        <div className="img-wrap">
          <img src={profile} alt="profile" />
        </div>
        <div className="head-wrap">
          <h4 className="head-title">Cogencis</h4>
          {/* <span className="status">Online</span> */}
          <ul className="details-list typ-group">
            {/* {recipientData?.members?.map((item) => (
              <li className="details-item">
                <span>{item}</span>
              </li>
            ))} */}
          </ul>
        </div>
      </div>
    </>
  );
}
