import React, { useEffect } from 'react';

const BadgeManager = ({ initialCount }) => {
  useEffect(() => {
    // Check if the browser supports the Badge API
    const isBadgeSupported = 'setAppBadge' in navigator && 'clearAppBadge' in navigator;

    if (isBadgeSupported) {
      // If the badge count is greater than 0, set the badge
      if (initialCount > 0) {
        navigator.setAppBadge()
          .then(() => console.log(`Badge set to: ${initialCount}`))
          .catch((e) => console.error('Error setting badge:', e));
      } else {
        navigator.clearAppBadge()
          .then(() => console.log('Badge cleared'))
          .catch((e) => console.error('Error clearing badge:', e));
      }
      return () => {
        navigator.clearAppBadge()
          .then(() => console.log('Badge cleared on unmount'))
          .catch((e) => console.error('Error clearing badge on unmount:', e));
      };
    } else {
      console.log('Badge API is not supported in this browser.');
    }
  }, [initialCount]);
  return null;
};

export default BadgeManager;