import React, { useEffect, useState } from "react";
import useChatModel from "../../../viewModel/useChatModel";
import ChatItem from "../chat-item";
import Search from "../../../../../common/components/search";
import profile from "../../../../../common/assets/images/profile.png";
import useSearchList from "../../../viewModel/useSearchList";
import { getInitials } from "../../../../../services/dataService";
import ProfileDetails from "../../../../../common/components/profile-details";

export default function NewChatList({ handleNewBroadcast, closeModal }) {
  const { handleChatClick } = useChatModel();
  const { searchList, searchText, handleClear, handleSearch } = useSearchList();
  const [openProfile, setOpenProfile] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      handleClear();
      closeModal();
    };

    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [closeModal]);

  const handleBroadcast = () => {
    handleNewBroadcast(true);
  };
  const handleProfileView = (name) => {
    //closeModal()
    setUserName(name);
    setOpenProfile(true);
  };
  const handleClose = () => {
    setOpenProfile(false);
  };

  const newHandleChatClick = (item) => {
    handleChatClick(item);
    closeModal();
    handleClear();
  };

  // const newClosedModel=()=>{
  //   closeModal()
  //     handleClear()
  // }

  return (
    <>
      <div className="drawer-head">
        <span className="cm-visible">New chat</span>
        <span onClick={closeModal}>
          <i className="icon close"></i>
        </span>
      </div>
      <Search
        handleSearch={handleSearch}
        handleClear={handleClear}
        searchText={searchText}
      ></Search>
      <div className="bs-section typ-connection typ-drawer">
        <div className="sec-cont">
          <div
            className="info-wrap"
            onClick={handleBroadcast}
            style={{ display: "none" }}
          >
            <i className="icon info"></i>
            <span>New Broadcast Message</span>
          </div>
          <div className="list-wrap">
            <ul className="list initial">
              {searchText === "" ? null : searchList &&
                searchList?.length > 0 ? (
                <>
                  {searchList.map((item, index) => (
                    <>
                      <li className="item">
                        <div className="mod-logo-text">
                          <div
                            className="img-wrap"
                            onClick={() =>
                              handleProfileView(item?.samAccountName)
                            }
                          >
                            {item.thumbnailPhoto ? (
                              <img
                                src={`data:image/png;base64,${item.thumbnailPhoto}`}
                                alt="profile"
                              />
                            ) : (
                              <span className="img-text">
                                {getInitials(item.name)}
                              </span>
                            )}
                          </div>
                          <div
                            className="head-wrap"
                            onClick={() => newHandleChatClick(item)}
                          >
                            <h4 className="title">{item.name}</h4>
                            <p className="subtitle">{item.title}</p>
                            <ul className="details-list">
                              <li className="details-item">
                                <span>{item.company}</span>
                              </li>
                              <li className="details-item">
                                <span>{item.department}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </>
                  ))}
                </>
              ) : searchText && searchList?.length === 0 ? (
                <>
                  <span className="cm-no-data-found">No Contacts Found</span>
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      {openProfile && (
        <div className="bs-modal">
          <div className="modal-overlay">
            <div className="modal-content">
              <ProfileDetails handleClose={handleClose} name={userName} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
