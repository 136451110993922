import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useConfigStore = create(
  persist(
    (set, get) => ({
      config: {
        fontSize: 1,
        //chatopenId:null,
        notification: {
          Chat_Show_Notifications: false,
          Chat_Alert_Sound:false,
          Groups_Alert_Sound:false,
          Groups_Show_Notifications: false,
          Show_Typing_Indicator: false,
          User_comes_online: false,
          User_goes_offline: false,
          Enable_Taskbar_Flashing: false,
          Enable_Flashing_Type:false
        },
        messaging: {
          Show_time_in_chat_window: false,
          Chat_History: false,
          Group_Chat_History: false,
          Group_Automatically_accept_invites: false,
          Group_Spotlight_your_name: false,
          Group_Spotlight_your_text: false,
        },
        muteChatroomList:[],
        muteUserList: [],
        
      },

      setAllConfig: (payload) => {
        return set({ config: payload });
      },
      setConfig: (payload) => {
        return set((prevState) => ({
          config: {
            ...prevState.config,
            [payload.key]: payload.value,
          },
        }));
      },
      setMuteUserList: (email) => {
        return set((prevState) => {
          const muteUserList = prevState.config.muteUserList || [];

          const updatedMuteUserList = [...new Set([...muteUserList, email])];

          return {
            config: {
              ...prevState.config,
              muteUserList: updatedMuteUserList,
            },
          };
        });
      },
      removeFromMuteUserList: (email) => {
        return set((prevState) => {
          const muteUserList = prevState.config.muteUserList || [];

          const updatedMuteUserList = muteUserList.filter(
            (userEmail) => userEmail !== email
          ); // Remove email

          return {
            config: {
              ...prevState.config,
              muteUserList: updatedMuteUserList,
            },
          };
        });
      },

      clearMuteUserList: () => {
        return set((prevState) => {
          return {
            config: {
              ...prevState.config,
              muteUserList: [],
            },
          };
        });
      },

      setmuteChatroomList: (email) => {
        return set((prevState) => {
          const muteChatroomList = prevState.config.muteChatroomList || [];

          const updatedMuteUserList = [...new Set([...muteChatroomList, email])];

          return {
            config: {
              ...prevState.config,
              muteChatroomList: updatedMuteUserList,
            },
          };
        });
      },
      removeFrommuteChatroomList: (email) => {
        return set((prevState) => {
          const muteChatroomList = prevState.config.muteChatroomList || [];

          const updatedMuteUserList = muteChatroomList.filter(
            (userEmail) => userEmail !== email
          ); // Remove email

          return {
            config: {
              ...prevState.config,
              muteChatroomList: updatedMuteUserList,
            },
          };
        });
      },

      clearmuteChatroomList: () => {
        return set((prevState) => {
          return {
            config: {
              ...prevState.config,
              muteChatroomList: [],
            },
          };
        });
      },

      setFontSize: (value) =>
        set((prevState) => {
          const newSize =
            typeof value === "function"
              ? value(prevState.config.fontSize)
              : value;
          return {
            config: {
              ...prevState.config,
              fontSize: Number(newSize) || 1, // Ensure it's a number and default to 1 if invalid
            },
          };
        }),
    resetSettingStore: () => set({
        config: {
            fontSize: 1,
            //chatopenId:null,
            notification: {
                Chat_Show_Notifications: false,
                Chat_Alert_Sound:false,
                Groups_Alert_Sound:false,
                Groups_Show_Notifications: false,
                Show_Typing_Indicator: false,
                User_comes_online: false,
                User_goes_offline: false,
                Enable_Taskbar_Flashing: false,
                Enable_Flashing_Type:false
            },
            messaging: {
                Show_time_in_chat_window: false,
                Chat_History: false,
                Group_Chat_History: false,
                Group_Automatically_accept_invites: false,
                Group_Spotlight_your_name: false,
                Group_Spotlight_your_text: false,
            },
            muteChatroomList:[],
            muteUserList: [],

        }
    }),
    }),
    {
      name: "settings",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useConfigStore;
