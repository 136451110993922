import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// Zustand store with persist middleware for storing configurations
const useGroupchatStore = create(
  persist(
    (set, get) => ({
      // Initial state
      groups: [],
      contacts: [],
      selectedGroup: null,
      messages: [],
      message: '',
      showMoreMenu: false,
      showEmojiPicker: false,

      // Actions to update the state
      setGroups: (groups) => set({ groups }),
      setContacts: (contacts) => set({ contacts }),
      setSelectedGroup: (group) => set({ 
        selectedGroup: group, 
        messages: group.messages || [] 
      }),
      setMessages: (messages) => set({ messages }),
      setMessage: (message) => set({ message }),
      setShowMoreMenu: (showMoreMenu) => set({ showMoreMenu }),
      setShowEmojiPicker: (showEmojiPicker) => set({ showEmojiPicker }),

      // Action to send a message
      sendMessage: () => set((state) => {
        const newMessage = {
          from: 'currentUser', // Replace with the actual current user
          message: state.message,
          time: new Date().toISOString(),
        };
        const updatedMessages = [...state.messages, newMessage];
        return { messages: updatedMessages, message: '' };
      }),

      // Action to toggle the emoji picker visibility
      toggleEmojiPicker: () => set((state) => ({
        showEmojiPicker: !state.showEmojiPicker
      })),
        resetGroupChatStore: () => set({
            groups: [],
            contacts: [],
            selectedGroup: null,
            messages: [],
            message: '',
            showMoreMenu: false,
            showEmojiPicker: false,
        }),
    }),
    {
      name: "config-store", // Name of the item in storage
      storage: createJSONStorage(() => localStorage), // Store to local storage
    }
  )
);

export default useGroupchatStore;
