import debounce from "lodash.debounce";
import React, { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import Search from "../../../../../common/components/search";
import SelectedUsers from "../../../../../common/components/selected-users";
import { getAPiEndpoint } from "../../../../../config/apiEndpoints";
import api from "../../../../../services/axiosInterceptor";
import { getInitials } from "../../../../../services/dataService";
import useLoginStore from "../../../../../store/loginStore";
import { toast, ToastContainer } from "react-toastify";

export default function NewChatroomList({
  closeModal,
  details,
  existingGroup,
}) {
  const navigate = useNavigate();

  const [groupName, setGroupName] = useState("");
  const [groupImage, setGroupImage] = useState(null);
  const [groupDesc, setGroupDesc] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showTextInput, setShowTextInput] = useState(false);
  const [isSearchApiActive, setIsSearchApiActive] = useState(false);
  const loggedUserData = useLoginStore();
  // let loggedUsername = useLoginStore((state) => state.username);
  // loggedUsername = loggedUsername.toLowerCase();
  const handleImageChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      // Check the file type
      const fileType = file.type;
      if (!["image/jpeg", "image/png"].includes(fileType)) {
        toast.error("Please upload a JPEG or PNG image.");
        return;
      }

      // Check the file size (should be less than 100 kB)
      if (file.size > 100 * 1024) {
        toast.error("Image size should be less than 100 kB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target.result;
        // Create an image element to check the dimensions
        console.log("result-->", result);
        const img = new Image();
        img.src = result;
        img.onload = () => {
          // Commented for now for resting purpose
          // if (img.width !== 96 || img.height !== 96) {
          //   toast.info("Image dimensions should be 96x96 pixels.");
          //   return;
          // }
          const imageUrl = URL.createObjectURL(file);
          setGroupImage(imageUrl);
          setImageFile(reader.result); // Image is stored as base 64
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClear = () => {
    setSearchText("");
  };

  const handleChange = (value) => {
    setSearchText(value);
    if (value.length > 0) {
      try {
        setIsSearchApiActive(true);
        fetchUsers(value);
      } catch (e) {
      }
    } else {
      setSearchedUsers([]);
    }
  };

  const fetchUsers = useCallback(
    debounce(async (user) => {
      try {
        const response = await api.get(getAPiEndpoint("getSearchUser", `${user}`));
        if (response.data.success) {
          let memberList = [];
          response?.data?.data.map((items) => {
            const {members = []} = details || {members: []};
            console.log("memberList", items.email, members);
            if (
                !members.includes(
                    items?.samAccountName?.toLowerCase() + "@cogencis.com"
                )
            ) {
              console.log("memberList", items.email);
              memberList.push(items);
            }
          });
          setSearchedUsers(memberList);
        }
      } catch (e) {
      } finally {
        setIsSearchApiActive(false)
      }
    }, 500),
    []
  );

  const selectUsers = (item) => {
    const memberIndex = selectedUsers.findIndex(
      (i) => i.samAccountName === item.samAccountName
    );
    if (memberIndex !== -1) {
      const memberPresent = [...selectedUsers];
      memberPresent.splice(memberIndex, 1); // Remove the user by index
      setSelectedUsers(memberPresent);
    } else {
      setSelectedUsers([...selectedUsers, item]);
    }
  };

  const handleSubmitNewGroup = async () => {
    let owner =
      loggedUserData?.userInformation[0]?.samAccountName + "@cogencis.com";
    owner = owner.toLowerCase();

    const groupExists = !!details;
    if (!groupExists) {
      if (!groupName || !groupName?.toString().trim()) {
        toast.error("Please Enter Group name");
        return;
      }
      if (!selectedUsers || selectedUsers.length <= 0) {
        toast.error("Please select Members");
      }
    }
    const roomName = groupName?.replace(/\s+/g, "-");
    const payload = {
      roomName: roomName + "-" + uuidv4(),
      naturalName: groupName,
      description: groupDesc,
      subject: imageFile,
      // creationDate: moment(),
      // modificationDate: moment(),
      // maxUsers: "",
      persistent: "true",
      // publicRoom: "true",
      // registrationEnabled: "false",
      // canAnyoneDiscoverJID: "true",
      // canOccupantsChangeSubject: "false",
      // canOccupantsInvite: "false",
      // canChangeNickname: "false",
      // logEnabled: "true",
      // loginRestrictedToNickname: "false",
      membersOnly: "true",
      // moderated: "false",
      allowPM: "anyone",
      broadcastPresenceRoles: ["moderator", "participant", "visitor"],
      owners: [owner],
      // admins: [owner],
      members: selectedUsers.map(
        (item) => item.samAccountName.toLowerCase() + "@cogencis.com"
      ),
      // outcasts: ["outcast@localhost"],
    };

    try {
      // if Group Doest not exist create a new Group;
      if (!groupExists) {
        const createResponse = await api.post(
          getAPiEndpoint("createChatRoom"),
          payload
        );
        console.log("Create Group Response", createResponse);

        if (createResponse.data.success) {
          closeModal({refresh: true});
          navigate("/group", { state: { refresh: true } });
        }
      } else {
        selectedUsers &&
          selectedUsers?.map(async (user) => {
            await api.post(
              getAPiEndpoint(
                "addUserToChatRoom",
                `/${details.roomName}/members/${
                  user.samAccountName.toLowerCase() + "@cogencis.com"
                }`
              )
            );
          });
        closeModal({refresh: true});
        navigate("/group", { state: { refresh: true } });
      }
    } catch (error) {
      console.error("Error handling group submission", error);
    }

    closeModal({refresh: true});
    navigate("/group", { state: { refresh: true } });
  };
  console.log("selected users", selectedUsers);
  return (
    <>
      {!existingGroup && existingGroup == undefined && (
        <div className="drawer-head">
          <span className="cm-visible">New Group</span>
          <span onClick={closeModal}>
            <i className="icon close"></i>
          </span>
        </div>
      )}
      {selectedUsers && selectedUsers.length > 0 && (
        <>
          {!existingGroup && existingGroup == undefined && (
            <>
              <div className="cm-group-title">
                {groupImage ? (
                  <div className="group-profile">
                    <img
                      src={groupImage}
                      alt="Group"
                      className="group-image-preview"
                      onClick={() =>
                        document.getElementById("groupImageInput").click()
                      }
                    />
                  </div>
                ) : (
                  <i
                    className="camera"
                    onClick={() =>
                      document.getElementById("groupImageInput").click()
                    }
                  ></i>
                )}
                <input
                  type="text"
                  placeholder="Group Name"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
                <input
                  type="file"
                  id="groupImageInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </div>
              <p className="cm-drawer-title">
                Select members to add to the Group
              </p>
            </>
          )}
          <SelectedUsers
            selectedList={selectedUsers}
            showAddButton={showTextInput}
            handleAddMore={() => setShowTextInput(false)}
            onRemove={selectUsers}
          />
          {showTextInput ? (
            <span className="cm-done-btn" onClick={handleSubmitNewGroup}>
              <i className="icon done"></i>
            </span>
          ) : (
            <span
              className="cm-done-btn"
              onClick={() => setShowTextInput(true)}
            >
              <i className="icon done"></i>
            </span>
          )}
        </>
      )}
      {showTextInput ? (
        !existingGroup &&
        existingGroup == undefined && (
          <div className="listing-group">
            <div className="input-group">
              <textarea
                value={groupDesc}
                onChange={(e) => setGroupDesc(e.target.value)}
                placeholder="Write Group Description..."
              />
            </div>
          </div>
        )
      ) : (
        <>
          <Search
            placeholder={"Search Users"}
            searchText={searchText}
            handleClear={handleClear}
            handleSearch={handleChange}
          />
          <div className="bs-section typ-connection typ-drawer">
            <div className="sec-cont">
              <ul
                className={`list ${
                  !existingGroup ? "typ-group" : "typ-group-modal"
                } ${selectedUsers.length >= 1 ? "compressed" : ""}`}
              >
                {searchedUsers && searchedUsers.length > 0 ? (
                  <>
                    {searchedUsers.map((item, index) => (
                      <li className="item" key={index}>
                        <div className="mod-logo-text">
                          <div className="img-wrap">
                            <span className="img-text">
                              {getInitials(item?.name)}
                            </span>
                            {/* <img src={profile} alt="profile" /> */}
                          </div>
                          <div className="head-wrap">
                            <h4 className="title">{item?.name}</h4>
                            <ul className="details-list">
                              <li className="details-item">
                                <span>{item?.department}</span>
                              </li>
                            </ul>
                          </div>
                          <label for={`group-${index}`}></label>
                          <input
                            type="checkbox"
                            name="Group"
                            id={`group-${index}`}
                            onChange={(ev) => selectUsers(item)}
                            checked={selectedUsers.includes(item)}
                          />
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    <span className="cm-no-data-found">{!searchText ? "Search User": isSearchApiActive ? "loading Contacts" : `No Contacts Found ${isSearchApiActive}`}</span>
                  </>
                )}
              </ul>
            </div>
          </div>
        </>
      )}
      {/* <ToastContainer autoClose={2000} /> */}
    </>
  );
}
