import React from "react";
import { Navigate } from "react-router-dom";
import useLoginStore from "../store/loginStore";
import { getAuthData } from '../services/auth';

const AuthGuard = ({ children }) => {
 const getCookieData=getAuthData();
 // const { token } = useLoginStore();

  const isAuthenticated = () => {
    if (getCookieData?.token) return true;
    else return false;
  };

  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default AuthGuard;


