import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChatroomDetails from "../../../../../common/components/chatroom-details";
import { getInitials } from "../../../../../services/dataService";

export default function ChatroomProfile() {
  const location = useLocation();
  const [openGroupDetails, setOpenGroupDetails] = useState(false);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/group");
  };
  const handleGroupView = () => {
    setOpenGroupDetails(true);
  };
  const handleClose = () => {
    setOpenGroupDetails(false);
  };
  return (
    <>
      <div className="action-wrap cm-visible" onClick={handleGoBack}>
        <i className="back"></i>
      </div>

      <div className="mod-logo-text" onClick={handleGroupView}>
        <div className="img-wrap">
          {location?.state?.subject ? (
            <div className="group-profile">
              <img
                src={location.state.subject}
                alt="Group"
                className="img-wrap"
              />
            </div>
          ) : (
            <span className="img-text">
              {getInitials(location.state.roomName)}
            </span>
          )}
        </div>

        {/* Group Name */}
        <div className="head-wrap">
          <h4 className="head-title">{location.state.naturalName}</h4>
          {/*<h4 className="head-title">{location.state.roomName}</h4>*/}
          <ul className="details-list typ-group">
            {location.state?.members?.map((item, index) => (
              <li key={index} className="details-item">
                <span>
                  {item}
                  {index < location.state.members.length - 1 ? "," : ""}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {openGroupDetails && (
        <div className="bs-modal">
          <div className="modal-overlay" onClick={handleClose}>
            <div
              className="modal-content right-wrap full-page"
              onClick={(e) => e.stopPropagation()}
            >
              <ChatroomDetails onClose={handleClose} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
