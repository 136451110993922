import { FormControl, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AttachmentBtn from "../../../../../common/components/attachment-btn";
import ConferenceInviteBtn from "../../../../../common/components/conference-invite-btn";
import FontBtn from "../../../../../common/components/font-btn";
import ScreenCaptureBtn from "../../../../../common/components/screen-capture-btn";
import ConferenceProfile from "../conference-profile";

export default function ConferenceChatDetails() {
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const moreMenuRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
        setShowMoreMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreMenuRef, setShowMoreMenu]);
  return (
    <>
      <div className="right-wrap">
        <section>
          <div className="bs-page-head">
            <ConferenceProfile />
            <div className="cta-wrap cm-hidden">
              <ul className="list">
                {/* <FontBtn /> */}
                <ScreenCaptureBtn />
                <AttachmentBtn />
                <ConferenceInviteBtn />
              </ul>
            </div>
            <button
              className="btn-more"
              onClick={() => {
                setShowMoreMenu(true);
              }}
            >
              <i className="icon more"></i>
            </button>
            {showMoreMenu && (
              <div className="menu-overlay">
                <div ref={moreMenuRef} className="more-menu-content">
                  <ul className="list">
                    <li className="item">
                      <div className="mod-icon-text">
                        <i className="icon mute"></i>
                        <p className="text">Mute Conversation</p>
                      </div>
                    </li>
                    <li className="item">
                      <div className="mod-icon-text">
                        <i className="icon unread"></i>
                        <p className="text">Mark as unread</p>
                      </div>
                    </li>
                    {/* <li className="item">
                      <div className="mod-icon-text">
                        <i className="icon delete"></i>
                        <p className="text">Delete Conversation</p>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </section>
        <section>
          <div className="bs-section typ-main">
            <div className="bs-chat">
              <div className="message-group">
                {/* <span className="chat-date">Today</span> */}
                {/* <ul className="list" ref={containerRef}>
                  {messages &&
                    messages.map((message, index) => (
                      <>
                        {message?.from == recipientData?.username && (
                          <li className="item receive">
                            <div className="img-wrap">
                              <span>{getInitials(recipientData?.name)}</span>
                            </div>
                            <div className="wrap">
                              <p className="text">{message?.message}</p>
                              <span className="time">
                                {moment(message?.time).format("hh:mm A")}
                              </span>
                            </div>
                          </li>
                        )}
                        {message?.from == userCredentials?.username && (
                          <li className="item send">
                            <div className="img-wrap">
                              <span>{getInitials(userCredentials?.name)}</span>
                            </div>
                            <div className="wrap">
                              <p className="text">{message?.message}</p>
                              <span className="time">
                                {moment(message?.time).format("hh:mm A")}
                              </span>
                            </div>
                          </li>
                        )}
                      </>
                    ))}
                </ul> */}
                <div className="input-group">
                  <div className="message-wrap">
                    <FormControl variant="standard">
                      <TextField
                        id="message"
                        multiline
                        maxRows={4}
                        // value={message}
                        // onKeyDown={handleKeyDown}
                        // onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type here..."
                        name="message"
                      />
                    </FormControl>
                  </div>
                  <ul className="cta-list">
                    <ScreenCaptureBtn />
                    <AttachmentBtn />
                  </ul>
                  <button
                  //   onClick={sendMessage}
                  >
                    <i className="icon message-send"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
