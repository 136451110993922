
/**
 * Filter with in array of Objects
 * list: for data of list to be filtered on
 * value:- text to be search on
 * key:- key on which it is to be filtered
 * */
export function filterInArrayOfObject(list, value, key) {
    if (list && key && value) {
        const lowerCaseSearchTerm = value?.toLowerCase();
        return list.filter(room => room?.[key]?.toLowerCase().includes(lowerCaseSearchTerm));
    }
    return [];
}
