import axios from "axios";
import CryptoJS from "crypto-js";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify"; // Example with react-toastify
import "react-toastify/dist/ReactToastify.css";
import {getAPiEndpoint} from "../../../config/apiEndpoints";
import getDeviceType from "../../../services/deviceTypeService";
import useLoginStore from "../../../store/loginStore";
import useXmppStore from "../../../store/xmppStore";
import useConfigStore from "../../../store/settingsStore";
import {getAuthData, setAuthData,} from "../../../services/auth";
import api from "../../../services/axiosInterceptor";
import useContactListStore from "../../../store/contactList";

function useLoginModel() {
    const connectXMPP = useXmppStore((state) => state.connectXMPP);
    const joinOrCreateChatroom = useXmppStore((state) => state.joinOrCreateChatroom);
    const { setChatRoomList } = useContactListStore();
    const {config, setAllConfig} = useConfigStore();
    const {token} = useLoginStore();
    const navigate = useNavigate();
    const setUserCredentials = useXmppStore((state) => state.setUserCredentials);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = "Username is required";
        }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
            remember: false,
        },
        validate,
        onSubmit: (values) => {
            if (values.remember) {
                // Cookies.set("Username", values.username, { expires: 365 });
                // Cookies.set("Password", values.password, { expires: 365 });
                // Cookies.set("RememberMe", String(values.remember), { expires: 365 });
            } else {
                // Cookies.remove("Username");
                // Cookies.remove("Password");
                // Cookies.remove("RememberMe");
            }
            handleLogin(values);
        },
    });

    const handleUserDetails = useLoginStore((state) => state.handleUserDetails);
    const setFileTypes = useLoginStore((state) => state.setFileTypes);

    // useEffect(() => {
    //     const getCookieData = getAuthData();
    //     if (!token && getCookieData?.token) {
    //         let values = {
    //             username: getCookieData?.userName,
    //             password:getCookieData?.password //"C0gencis@123"
    //         }
    //         let response_Auth = {
    //             data: {
    //                 token: getCookieData.token,
    //                 expiry: getCookieData.expiry,
    //                 refreshToken: getCookieData.refreshToken,
    //                 username: getCookieData.userName,
    //             }
    //         }
    //         var enPassword = CryptoJS.AES.encrypt(
    //             values.password,
    //             process.env.REACT_APP_KEY
    //         ).toString();
    //         getUserSessionAndDetails(values, response_Auth, enPassword)
    //     }
    // }, []);

    useEffect(() => {
      const initializeSession = async () => {
          const getCookieData = getAuthData();
          if (!getCookieData?.expiry) return; 
          if (!token && getCookieData?.token) {
              let values = {
                  username: getCookieData?.userName,
                  password: getCookieData?.token, // e.g., "C0gencis@123"
              };
              let response_Auth = {
                  data: {
                      token: getCookieData.token,
                      expiry: getCookieData.expiry,
                      refreshToken: getCookieData.refreshToken,
                      username: getCookieData.userName,
                  },
              };
            //   var enPassword = CryptoJS.AES.encrypt(
            //       values.password,
            //       process.env.REACT_APP_KEY
            //   ).toString();

              try {
                 setLoading(true); // Start loading
                  await getUserSessionAndDetails(values, response_Auth);
              } catch (error) {
                  console.error('Error initializing session:', error);
              } finally {
                  setLoading(false); // End loading
              }
          }
      };
      initializeSession();
  }, []);

    const getUserSessionAndDetails = async (values, response_Auth) => {
        console.log('getUserSessionAndDetails->', values, response_Auth);
        const response = await axios.get( getAPiEndpoint("getUserDetails", values.username),
            {
                headers: {
                    Authorization: `Bearer ${response_Auth?.data?.token}`,
                },
            }
        );
        const message = await connectXMPP(
            values.username,
            values.password,
            getDeviceType()
        );
        console.log("Connection message:", message);
        if (response?.data?.success && message?.message === "Connected successfully") {
            const token = response_Auth?.data?.token;
            const authData = {
                token: response_Auth?.data?.token,
                expiry: response_Auth?.data?.expiry,
                refreshToken: response_Auth?.data?.refreshToken,
                userName: response?.data?.data[0]?.samAccountName,
            };
            
            setAuthData(authData);
            const response1 = await axios.get(
                getAPiEndpoint("getSettings", `${values.username}@cogencis.com`),
                {headers: {Authorization: `Bearer ${token}`}}
            );
            if (response1.data.response.data[0]?.setting) {
                try {
                    const getdetails = response1.data.response.data[0]?.setting;
                    const objectData = JSON.parse(getdetails);
                    setAllConfig(objectData);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            } //else {
            //   console.error("Setting is undefined or null");
            // }

            const userdetails = response?.data?.data || [];
            const resp = await axios.get(
                getAPiEndpoint("fileType", userdetails[0]?.ou),
                {headers: {Authorization: `Bearer ${token}`}}
            );

            const fileTypeString = resp?.data?.response?.data[0]?.extensions
            const fileType = JSON.parse(fileTypeString)

            setFileTypes(fileType?.fileTypes)

            handleUserDetails(token, values.username, userdetails);
            navigate("/chat");
            setUserCredentials({
                jid: `${values.username}@cogencis.com`,
                username: values.username,
                name: values.name,
                email: values.email ? values.email : `${values.username}@cogencis.com`,
                token: token,
                isLoggedIn: true,
            });

            if (values?.username){
                let loggedUser=`${values.username.toLowerCase()}@cogencis.com`
                const fetchGroups = async () => {
                    // const data = await api.get(getAPiEndpoint("getChatRooms", "?type=all"));
                    const data = await api.get(getAPiEndpoint(`getChatRoomsList`,`${loggedUser}&pageSize=10&pageNo=${1}`));
                    if (data?.data) {
                        let chatRoomData = data.data?.response?.chatrooms;
                        console.log("chatrooms-data--->", chatRoomData);
                        setChatRoomList(chatRoomData || []);
                        chatRoomData?.map((roomItems)=>{
                            const {roomName = ""} = roomItems;
                            if (roomName){
                                const roomJid = roomName+process.env.REACT_APP_CHAT_GROUP_DOMAIN
                                joinOrCreateChatroom(roomJid, values.username);
                            }
                        })
                    }
                };
                fetchGroups();
            }
        } else {
            console.error("Login failed: Invalid username or password");
            toast.error("Login failed: Invalid username or password", {
                position: "top-right",
            });
        }
    }

    const handleLogin = async (values) => {

        setLoading(true);
        var enPassword = CryptoJS.AES.encrypt(
            values.password,
            process.env.REACT_APP_KEY
        ).toString();
        if (values.username && values.password) {
            const data = {
                username: values.username,
                password: enPassword//values.password,
            };
            try {
                const response_Auth = await axios.post(getAPiEndpoint("loginAuth"), data);
                if (!response_Auth || !response_Auth.data || !response_Auth.data.sucess) {
                    toast.error("Login failed: Invalid username or password", {
                        position: "top-right",
                    });
                    return;
                }
                if (response_Auth?.data?.entitlements?.hasOwnProperty("chat")) {
                  await  getUserSessionAndDetails(values, response_Auth, enPassword);
                } else {
                    toast.error(
                        "This user’s Connect module is not yet active. Please reach out for assistance if needed",
                        {
                            position: "top-right",
                        }
                    );
                    console.error(
                        "This user’s Connect module is not yet active. Please reach out for assistance if needed"
                    );
                }
            } catch (error) {
                console.error("Login error:", error);
                toast.error("Something went wrong. Please try again!", {
                    position: "top-right",
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return {
        formik,
        loading,
        showPassword,
        togglePasswordVisibility,
    };
}

export default useLoginModel;
