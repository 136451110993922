import React, { useEffect, useRef, useState } from "react";
import * as Strophe from "strophe.js";
import { v4 as uuidv4 } from "uuid";
import { FormControl, TextField } from "@mui/material";
import getDeviceType from "../../../../../services/deviceTypeService";
import { toast } from "react-toastify";
import moment from "moment";
import { getAuthData } from "../../../../../services/auth";
import useLoginStore from "../../../../../store/loginStore";

const  escapeHTML=(message) =>{
  return message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

const SupportChat = () => {
  const { userInformation } = useLoginStore();
  const [isConnected, setIsConnected] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [agentQueueJid, setAgentQueueJid] = useState(
    process.env.REACT_APP_CUSTOMER_SUPPORT_ID
  );
  const [agentRoomJid, setAgentRoomJid] = useState(
    process.env.REACT_APP_CUSTOMER_SUPPORT_ID
  );
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);
  const xmppClient = useRef(null);
  const agentRoomJidRef = useRef(agentRoomJid);
  const clientJidNameRef = useRef(null);
  const clientJidTokenRef = useRef(null);
  const userNickNameRef = useRef(null);

  // Connect to the XMPP server when the component mounts
  useEffect(() => {
    const getStorageData = getAuthData();
    userNickNameRef.current = getStorageData.userName;
    clientJidNameRef.current = `${getStorageData.userName}${process.env.REACT_APP_USER_EMAIL_DOMAIN}`;
    clientJidTokenRef.current = getStorageData.token;
    if (getStorageData.token) {
      connectSupportXMPP();
    }
    // Clean up and disconnect when the component unmounts
    return () => {
      // disconnectXMPP();
    };
  }, []);

  useEffect(() => {
    agentRoomJidRef.current = agentRoomJid;
  }, [agentRoomJid]);

  // Scroll to the bottom whenever new messages are added
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const connectSupportXMPP = () => {
    if (xmppClient.current) {
      return;
    }
    xmppClient.current = new Strophe.Strophe.Connection(
      process.env.REACT_APP_SOCKET_URL
    );
    xmppClient.current.connect(
      clientJidNameRef.current,
      clientJidTokenRef.current,
      onConnect
    );
  };

  const onConnect = (status) => {
    switch (status) {
      case Strophe.Strophe.Status.CONNECTING:
        setConnectionStatus("Connecting...");
        break;
      case Strophe.Strophe.Status.CONNFAIL:
        setConnectionStatus("Connection Failed");
        setError("Unable to connect to XMPP server");
        startNewSession();
        break;
      case Strophe.Strophe.Status.CONNECTED:
        setConnectionStatus("Connected");
        setIsConnected(true);
        setError(null);
        sendPresence();
        break;
      case Strophe.Strophe.Status.DISCONNECTED:
        setConnectionStatus("Disconnected");
        setIsConnected(false);
        break;
      default:
        break;
    }
  };

  const sendPresence = () => {
    const presence = Strophe.$pres()
      .c("show")
      .t("groupchat")
      .up()
      .c("status")
      .t("online")
      .c("priority")
      .t("1");
    xmppClient.current.send(presence);
    joinQueue();
  };

  const joinQueue = () => {
    const joinQueueIQ = Strophe.$iq({
      id: `${uuidv4()}:sendIQ`,
      to: agentQueueJid,
      type: "get",
      from: clientJidNameRef.current,
    }).c("vCard", { xmlns: "vcard-temp" });
    xmppClient.current.sendIQ(
      joinQueueIQ,
      onJoinQueueSuccess,
      onJoinQueueError
    );
  };

  const onJoinQueueSuccess = (response) => {
    // console.log('Successfully joined the queue:', response);
    setConnectionStatus("Queue Joined Successfully");
    startListeningForMessages();
  };

  const onJoinQueueError = (error) => {
    // console.error('Failed to join the queue:', error);
    setError("Error joining the queue");
  };

  // Listen for incoming messages from the agent
  const startListeningForMessages = () => {
    xmppClient.current.addHandler(
      (message) => {
        // console.log('message-Received-->', message);
        const from = message.getAttribute("from");
        const to = message.getAttribute("to");
        const type = message.getAttribute("type");
        const body = message.getElementsByTagName("body")[0]?.textContent;
        if (body) {
          if (type === "error") {
            return true;
          }

          if (from?.toString()?.trim()?.endsWith(userNickNameRef.current)) {
            return true;
          }

          setMessages((prevMessages) => [
            ...prevMessages,
            {
              from: from,
              to: to?.toString()?.split("/")[0],
              body: body,
              date: moment().valueOf(),
              type: "chat",
            },
          ]);
        } else {
          // Assuming xmlData is already a parsed XML DOM object
          // Extract the 'reason' from within the 'invite' tag
          const reasonText = message.querySelector("reason")?.textContent || "";
          if (reasonText === "Please join me for a chat.") {
            // Extract the 'from' attribute from the 'invite' tag
            // const fromText = message.querySelector('invite')?.getAttribute('from') || '';
            // Extract the 'jid' attribute from the <x xmlns="jabber:x:conference"> tag
            const jidText =
              message.querySelector("x[jid]")?.getAttribute("jid") || "";
            // console.log('from-->', {reasonText, jidText, fromText});
            joinRoom(jidText);
            setAgentRoomJid(jidText);
          }
        }
        return true; // Keep the handler active
      },
      null,
      "message",
      null,
      null,
      null
    );
    startListeningForPresence();
  };

  const startListeningForPresence = () => {
    xmppClient.current.addHandler(
      (presence) => {
        const from = presence.getAttribute("from");
        const type = presence.getAttribute("type");
        // Handle presence update (such as when agent leaves)
        if (
          type === "unavailable" &&
          from &&
          agentRoomJidRef?.current === from?.split("/")[0]
        ) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              from: "system",
              body: `The Agent has left the chat.`,
              date: moment().valueOf(),
              type: "system",
            },
          ]);
          setAgentRoomJid(agentRoomJid);
          startNewSession();
          // setIsConnected(false);  // Optionally mark the session as disconnected
        }
        return true; // Keep the handler active for future presence updates
      },
      null,
      "presence",
      null,
      null,
      null
    );
  };

  // Function to join the room with the extracted jidText
  const joinRoom = (roomJid) => {
    if (!roomJid) {
      console.error("No JID provided for the room.");
      return;
    }

    // Send a presence stanza to join the room
    const presence = Strophe.$pres({
      // to: roomJid + `/${}`  // `chat_user` is an example nickname for the user
      to: `${roomJid}/${userNickNameRef.current}`, // `chat_user` is an example nickname for the user
    }).c("x", { xmlns: "http://jabber.org/protocol/muc" });

    xmppClient.current.send(presence);
    // Optionally, update the UI or internal state to reflect that you're now in the room
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        from: "system",
        body: `Joined the room: ${roomJid}`,
        date: moment().valueOf(),
        type: "system",
      },
    ]);
  };

  // Send a message to the agent
  const sendMessage = () => {
    let sendMessage = messageInput?.toString().trim();
    if (!sendMessage) {
      // toast.error("Can not send empty Message");
      return;
    }
    if (!isConnected || !xmppClient?.current) {
      toast.error("Not able to connect to any Agent");
      return;
    }
    const message = Strophe.$msg({
      to: agentRoomJid,
      from: clientJidNameRef.current,
      type: "groupchat",
    })
      .c("body")
      .t(sendMessage);
    xmppClient.current.send(message);
    // Display the message in the UI
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        from: clientJidNameRef.current,
        to: agentRoomJid,
        body: sendMessage,
        date: moment().valueOf(),
        type: "groupchat",
      },
    ]);
    setMessageInput(""); // Clear the input after sending
  };

  const startNewSession = () => {
    // First, disconnect the existing session (if any)
    disconnectXMPP();
    // Reset relevant state (e.g., messages, room ID)
    // setMessages([]);
    setAgentRoomJid(agentQueueJid);
    setConnectionStatus("Disconnected");
    setIsConnected(false);
    // Now, initiate a new session by connecting to the XMPP server again
    connectSupportXMPP();
  };

  // Disconnect from the XMPP server
  const disconnectXMPP = () => {
    if (xmppClient.current) {
      xmppClient.current.disconnect();
      xmppClient.current = null;
    }
  };

  const handleSendMessageButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.focus();
    }
    sendMessage();
  };

  const setRefs = (node) => {
    chatContainerRef.current = node;
  };

  return (
    <>
      <section>
        <div className="bs-section typ-main">
          <div className="bs-chat">
            <div className="message-group">
              <div className="chat-wrap" ref={setRefs}>
                <div className="chat-group">
                  {messages && messages.length > 0 ? (
                    <div className="date-header">{`Today`}</div>
                  ) : null}
                  <ul className="list">
                    {messages.map((item, id) => {
                      const isLoggedInUser =
                        clientJidNameRef.current === item.from;
                      return (
                        <li
                          key={id}
                          className={`item ${
                            isLoggedInUser ? "send" : "receive"
                          } `}
                        >
                          <div className="img-wrap">
                            {false ? (
                              <img
                                src={`data:image/png;base64,${userInformation[0].thumbnailPhoto}`}
                                alt="profile"
                              />
                            ) : (
                              <span className="img-text">A</span>
                            )}
                          </div>
                          <div className="wrap">
                            <p
                              key={id}
                              className="text"
                              dangerouslySetInnerHTML={{
                                __html: escapeHTML(item?.body).replace(/\n/g, "<br/>")
                              }}
                            ></p>
                            <span className="time">
                              {moment(item?.date || new Date()).format(
                                "hh:mm A"
                              )}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="input-group">
                <div className="message-wrap">
                  <FormControl variant="standard">
                    <TextField
                      id="message"
                      ref={inputRef}
                      multiline
                      maxRows={4}
                      value={messageInput}
                      onKeyDown={(e) => {
                        if (
                          getDeviceType() === "web" &&
                          e.key === "Enter" &&
                          !e.shiftKey
                        ) {
                          handleSendMessageButtonClick(e);
                        }
                      }}
                      onChange={(e) => {
                        setMessageInput(e.target.value);
                      }}
                      placeholder="Type here..."
                      name="message"
                    />
                  </FormControl>
                </div>

                <button
                  type="button"
                  onTouchStart={handleSendMessageButtonClick}
                  onMouseDown={handleSendMessageButtonClick}
                >
                  <i className="icon message-send" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SupportChat;
