import React from "react";
import profile from "../../assets/images/profile.png";
import { getInitials } from "../../../services/dataService";

export default function SelectedUsers({
  selectedList,
  showAddButton,
  handleAddMore,
  onRemove,
}) {
  const removeItem = (item) => {
    onRemove(item);
  };
  return (
    <>
      <section>
        <div className="bs-group">
          {/* <p className="group-title">Select members to add to the group</p> */}
          <span className="count-text">
            {selectedList?.length} members selected
          </span>
          <ul className="g-list">
            {selectedList && selectedList?.length > 0 && (
              <>
                {selectedList.map((item, index) => (
                  <li className="g-item" key={index}>
                    <div className="mod-profile">
                      <div className="img-wrap">
                        <span className="img-text">{getInitials(item?.name)}</span>
                        {/* <img src={profile} alt="profile" /> */}
                        <i
                          className="clear"
                          onClick={() => removeItem(item)}
                        ></i>
                      </div>
                      <h4 className="title">{item?.nickname}</h4>
                    </div>
                  </li>
                ))}
              </>
            )}
          </ul>
          {showAddButton && (
            <ul className="g-list">
              <li className="g-item">
                <div className="mod-profile">
                  <div className="img-wrap" onClick={handleAddMore}>
                    <i className="icon add"></i>
                  </div>
                  <h4 className="title">Add</h4>
                </div>
              </li>
            </ul>
          )}
        </div>
      </section>
    </>
  );
}
