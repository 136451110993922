import { useEffect, useState } from "react";

const getInitials = (fullName) => {
  if (!fullName) return "";
  const nameArray = fullName.split(" ");
  const initialsArray = nameArray.map((name) => name.charAt(0).toUpperCase());
  return initialsArray.join("");
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export { getInitials, useWindowSize };
