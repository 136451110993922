import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAPiEndpoint } from "../../../config/apiEndpoints";
import { useWindowSize } from "../../../services/dataService";
import { useGet } from "../../../services/useApiService";
import useChatStore from "../../../store/chatStore";
import useContactListStore from "../../../store/contactList";
import useLoginStore from "../../../store/loginStore";
import useXmppStore from "../../../store/xmppStore";
import useSearchList from "./useSearchList";
import useUnreadMessageStore from "../../../store/unreadMessageStore";

const useChatModel = () => {
  const { id } = useParams();
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const userCredentials = useXmppStore((state) => state.userCredentials);
  const [contacts, setContacts] = useState([]);
  const xmppClient = useXmppStore((state) => state.xmppClient);
  const username = useLoginStore((state) => state.username);

  const { userInformation } = useLoginStore();
  const handleSetRoster = useLoginStore((state) => state.handleSetRoster);
  const usernameForChat = username && username + "@cogencis.com";
  const [recipientData, setRecipientData] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [showNewChat, setShowNewChat] = useState(false);
  const [showBroadcastList, setShowBroadcastList] = useState(false);
  const [recipientOnline, setRecipientOnline] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [receiver, setReceiver] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  // const [addRosterData, setRosterData] = useState([]);
  // const refetchChatList = useXmppStore((state) => state.refetchChatList);
  // const setRefetchChatList = useXmppStore((state) => state.setRefetchChatList);

  const { searchValue, clearSerchVal } = useChatStore();
  // console.log("Roster",addRosterData);

  // const { handleClear } = useSearchList();
  const { addUnreadMsg, addMultipleUnreadMsgs } = useUnreadMessageStore();
  // useEffect(() => {
  //   contacts?.forEach((item) => {
  //     if (item?.unreadCount > 0) {
  //       const jid = item?.samAccountName.toLowerCase() + "@cogencis.com";
  //       const message = {
  //         message: item?.message,
  //         from: jid,
  //         date: item?.sentDate,
  //       };
  //       const unreadCount = parseInt(item?.unreadCount, 10);
  //       addUnreadMsg(jid, message, unreadCount);
  //     }
  //   });
  // }, [contacts]);

  useEffect(() => {
    contacts?.filter((item) => item?.unreadCount >0)?.map((items, index)=>(

      addMultipleUnreadMsgs(items?.samAccountName.toLowerCase() + "@cogencis.com",
         {
          message: items?.message,
          from: items?.samAccountName.toLowerCase() + "@cogencis.com",
          date: items?.sentDate,
        },
        items?.unreadCount
      )
  ))
  }, [contacts]);

  const handleEmojiSelect = (emojiObject) => {
    setMessage(message + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const setContactsList = useContactListStore((state) => state.setContactsList);
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const [scale, setScale] = useState(1);
  const [fontSize, setFontSize] = useState(16);

  const getUsersDataAction = {
    onSuccess: (data) => {
      setContacts(data?.data?.recentChats);
      setContactsList(data?.data?.recentChats);
      handleSetRoster(data?.data?.rosters);
    },
    onError: (error) => {},
    enabled: false,
  };
  const {
    isLoading: isUsersDataLoading,
    refetch: fetchUsers 
  } = useGet("getUsersRecent", getAPiEndpoint("getUsersRecent", `${usernameForChat}&pageNo=1&pageSize=10`), getUsersDataAction);
  // useEffect(() => {
  //   console.log("useEffect-->fetchUsers-->", {searchValue, usernameForChat}, new Date())
  //   if (usernameForChat && searchValue === "") {
  //     // console.log("useEffect-->fetchUsers-->", {searchValue, usernameForChat, isUsersDataLoading}, new Date())
  //     fetchUsers();
  //   }
  // }, [usernameForChat, clearSerchVal, searchValue]);

  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  const sendMessageToServer = (name, attachment) => {
    if (!xmppClient) {
      console.error("xmppClient is not initialized");
      return;
    }

    if (message.trim() === "" && !attachment) {
      console.error("Cannot send empty message");
      return;
    }

    const msgBody = {
      jid: userInformation?.[0]?.samAccountName + "@cogencis.com",
      body: message,
      to: name + "@cogencis.com",
      attachment: attachment ? attachment : null,
    };

    setMessages((prevMessages) => {
      return [
        ...prevMessages,
        {
          jid: userInformation?.[0]?.samAccountName + "@cogencis.com",
          message: message,
          to: name + "@cogencis.com",
          attachment: attachment ? attachment : null,
          time: moment(),
        },
      ];
    });
    // xmppClient.sendMessage(msgBody);
    // scrollToBottom();
    // if (chatContainerRef.current) {
    // chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    setMessage("");
    // setMessages([])
  };

  const handleFileUpload = (formData) => {
    const getFormData = Object.fromEntries(formData);
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}:`, pair[1]);
    }
    // Send FormData to the server
    // sendFileToServer(formData);

    if (!xmppClient) {
      console.error("xmppClient is not initialized");
      return;
    }
    const attachment = {
      file: formData.get("file"),
    };

    sendMessageToServer(id, attachment);
  };

  const handleChatClick = (user) => {
    if (user) {
      setReceiver(user.samAccountName);
      navigate(`/chat/${user?.samAccountName}`, { state: user });
      //setShowNewChat(false);
    }
  };

  // const handleChatClick = (user) => {
  //   if (user) {
  //     if (receiver === user.samAccountName) {
  //       // Close the chat if the same user is clicked again
  //       setReceiver(null); // Optionally, you can also handle any UI updates to close the chat window here
  //     } else {
  //       // Open a new chat for a different user
  //       setReceiver(user.samAccountName);
  //       navigate(`/chat/${user.samAccountName}`, { state: user });
  //     }
  //   }
  // };

  const openBroadCastModal = () => {
    setShowNewChat(false);
    setShowBroadcastList(true);
  };

  return {
    isUsersDataLoading,
    showEmojiPicker,
    setShowEmojiPicker,
    handleEmojiSelect,
    toggleEmojiPicker,
    scale,
    fontSize,
    showMoreMenu,
    setShowMoreMenu,
    contacts,
    width,
    location,
    id,
    sendMessageToServer,
    recipientData,
    messages,
    message,
    setMessage,
    setMessages,
    // handlekeyUp,
    recipientOnline,
    userCredentials,
    handleChatClick,
    setShowNewChat,
    showNewChat,
    setShowBroadcastList,
    showBroadcastList,
    openBroadCastModal,
    selectedChat,
    handleFileUpload,
    fetchUsers,
    setContacts,
    showPreview,
    setShowPreview,
  };
};

export default useChatModel;
