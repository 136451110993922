import { useEffect, useState } from "react";
import { getAPiEndpoint } from "../../../config/apiEndpoints";
import { useGet, useUpdate } from "../../../services/useApiService";
import useLoginStore from "../../../store/loginStore";
import useConfigStore from "../../../store/settingsStore";

const useSettingModel = () => {
  const { config, setAllConfig } = useConfigStore();
  const userInformation = useLoginStore(
    (state) => state.userInformation
  );

 
  const [selectMenu, setSelectMenu] = useState([]);

  const handletoggle = (e, name) => {
    const findObj = selectMenu?.find((elem) => elem?.name == name);
    if (findObj) {
      const filterObj = selectMenu?.filter(
        (elem) => elem?.name !== findObj?.name
      );
      findObj["check"] = e;
      setSelectMenu([...filterObj, findObj]);
    } else {
      setSelectMenu((prev) => [...prev, { name: name, check: e }]);
    }
  };
  const isChecked = (name) => {
    const item = selectMenu.find((elem) => elem.name === name);
    return item ? item.check : false;
  };

  const action = {
    onSuccess: (data) => {},
  };

  const { mutate: updateSettings } = useUpdate(
    "POST",
    getAPiEndpoint("updateSettings"),
    action
  );


  const getSettingAction = {
    onSuccess: (data) => {
      if (data?.response?.data?.[0]?.setting) {
        try {
          const getdetails = data.response.data[0].setting;
          const objectData = JSON.parse(getdetails);
          setAllConfig(objectData);
          // mergeSettings(objectData);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } else {
        console.error("Setting is undefined or null");
      }
    },
    
    onError: (error) => {},
    enabled: false,
  };

  const { isLoading: isUsersDataLoading, refetch: fetchSetting } = useGet(
    "getSettings",
    getAPiEndpoint("getSettings", `${userInformation?.[0]?.samAccountName}@cogencis.com`),
    getSettingAction
  );

  const preparePayload = () => {
    const payload = { ...config };

    selectMenu.forEach(({ name, check }) => {
      // Determine category based on name
      if (name in config.notification) {
        payload.notification[name] = check;
      } else if (name in config.messaging) {
        payload.messaging[name] = check;
      }
    });

    return {
      jid: `${userInformation?.[0]?.samAccountName}@cogencis.com`,
      settings: JSON.stringify(payload),
    };
  };
 // console.log("usernameForChat222"  , userInformation?.[0]?.samAccountName)

  // useEffect(()=>{
  //   if(userInformation){
  //     fetchSetting()
  //   }

  // },[userInformation])
  return {
    selectMenu,
    handletoggle,
    isChecked,
    updateSettings,
    fetchSetting,
    config,
    preparePayload,
  };
};

export default useSettingModel;
