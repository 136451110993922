import {create} from 'zustand';

const useChatDetailsStore = create((set) => ({
    messages:[],
    isMessagesLoading: null,
    pageNo: 1,
    scrollToBottom: true,
    hasMore: true,
    addMessages :(payload)=> set((state)=>({
        messages: [...state.messages, ...payload]
    })),
    addMessage:(payload)=> set((state)=>({
        messages: [...state.messages, payload]
    })),
    clearMessages:()=> set(()=>({
        messages: []
    })),
    setPageNo:(payload)=>set(()=>({
        pageNo: payload
    })),
    setIsMessagesLoading:(payload)=> set(()=>({
        isMessagesLoading: payload
    })),
    setScrollToBottom: (payload)=> set(()=>({
        scrollToBottom: payload
    })),
    setHasMore:(payload)=>set(()=>({
        hasMore: payload
    })),
    setDefaultChatDetailsState: ()=>set(()=>({
        messages:[],
        isMessagesLoading: null,
        pageNo: 1,
        scrollToBottom: true,
        hasMore: true,
    })),
    resetChatDetailsStore: () => set({
        messages: [],
        isMessagesLoading: null,
        pageNo: 1,
        scrollToBottom: true,
        hasMore: true,
    }),
}));

export default useChatDetailsStore;
