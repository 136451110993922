import React, { useEffect,useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoginStore from "../../../store/loginStore";

export default function AttachmentBtn({ onFileUpload}) {

     const {fileType} = useLoginStore()
  // console.log(fileType,"fileType");
  
  const MAX_COUNT = 5;

 const convertToBytes = (sizeStr) => {
  const sizeMap = {
    MB: 1024 * 1024,
    KB: 1024,
    B: 1,
  };

  const sizeParts = sizeStr.match(/(\d+)(MB|KB|B)/);
  if (sizeParts) {
    const [, size, unit] = sizeParts;
    return parseInt(size) * sizeMap[unit];
  }
  return 0; // default to 0 if not a valid format
};

  const validateFiles = (files) => {
    let limitExceeded = false;
    let validFiles = [];
    if (files.length > MAX_COUNT) {
      toast.info(`You can only add a maximum of ${MAX_COUNT} files`);
      limitExceeded = true;
      return;
    }
    files.forEach((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase(); // Get file extension
      const allowedFile = fileType.find((type) => type.type === fileExtension);
  
      if (!allowedFile) {
        toast.info(`Allowed types are: ${fileType.map(item => item.type).join(", ")}.`, { autoClose: 2000 });
      } else {
        const maxSizeInBytes = convertToBytes(allowedFile.maxSize);
  
        if (file.size > maxSizeInBytes) {
          toast.info(`The file ${file.name} exceeds the ${allowedFile.maxSize} limit`, { autoClose: 2000 });
        } else {
          validFiles.push(file); // Add file to valid list
        }
      }
    });

    if (!limitExceeded && validFiles.length > 0) {
      onFileUpload(validFiles);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    validateFiles(files);
  };

  const handleAttachmentClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf,.doc,.docx,.jpg,.jpeg,.png,.xls,.xlsx,.csv,.txt";
    fileInput.multiple = true;
    fileInput.style.display = "none";
    fileInput.onchange = handleFileChange;
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  };

useEffect(() => {
  const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      console.log("Files dropped:", files);
      validateFiles(files);
    }
  };
  window.addEventListener("dragover", preventDefault);
  window.addEventListener("drop", preventDefault);
  return () => {
    window.removeEventListener("dragover", preventDefault);
    window.removeEventListener("drop", preventDefault);
  };
}, []);

  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardItems = event.clipboardData.items;
      let pastedFiles = [];
      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          pastedFiles.push(file);
        }
      }
      if (pastedFiles.length > 0) {
        validateFiles(pastedFiles);
      }
    };
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <>
    <li className="item" >
      <button type="button" onClick={handleAttachmentClick}>
        <i className="icon attachment"></i>
        <span className="cta-text">Attachment</span>
      </button>
    </li>
      {/* <ToastContainer
         style={{ fontSize: '16px', marginTop: 65 }}
      position="top-center"
     // autoClose={1000}
      closeOnClick
      pauseOnHover
      // containerId="GlobalApplicationToast"
      /> */}
      </>
  );
}
