import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware';

const useLoginStore = create(persist(
    (set, get) => ({
        token: null,
        username: null,
        userInformation: {},
        password: null,
        fileType:[],
        loginSuccess: false,
        clientSetXmpp: false,
        rosters: [],
        setFileTypes: (fileType) =>{return set({ fileType: fileType })},
        clearPassword: () => set({ newPassword: '' }),
        handleUserDetails: (token, username,userInformation,password) => {
            return set({ token: token, username: username , userInformation: userInformation, password: password});
        },
        handleUserInformation:(payload)=>{
            return set({ userInformation: [payload]});
        },
        clearTokens: () => {
            return set({ token: null });
        },
        setLoginSuccess: () => {
            return set({ loginSuccess: true });
        },
        setClientSetXmpp: () => {
            return set({ clientSetXmpp: true });
        },
        setClientloginfalse: () => {
            return set({ clientSetXmpp: false ,loginSuccess: false});
        },
        // handleAddRoster: (newRoster) => {
        //    const currentRosters = get().rosters;
        //     // Directly add the new roster (jid) without any validation
        //     console.log("newRoster-----", currentRosters)
        //   return set({ rosters: [newRoster] });
        // },
        handleSetRoster: (newRoster) =>{
            console.log("contactsList--->newRoster-->", newRoster)
            return set((state) => ({
                rosters: newRoster, // If payload is a single message, add it directly
            }))
        },
        handleUpdateRoster: (newRoster) =>{
            return set((state) => ({
                rosters: [...state.rosters, ...[{jid: newRoster}]],
            }))
        },
        handleRemoveRoster: (rosterIdToRemove) => {
           const currentRosters = get().rosters;
            const index = currentRosters?.findIndex(item => {
                // console.log("item-->", item)
                return item?.jid?.toLowerCase().trim() === rosterIdToRemove
            });
            if (index !== -1) {
                currentRosters.splice(index, 1);
            }
            // Directly add the new roster (jid) without any validation
          return set({ rosters: currentRosters });
        },
        resetLoginStore: () => set({
            token: null,
            username: null,
            userInformation: {},
            password: null,
            fileType:[],
            loginSuccess: false,
            clientSetXmpp: false,
            rosters: [],
        }),
    }),
    {
        name: "login-store",
        storage: createJSONStorage(() => sessionStorage),
    }
))

export default useLoginStore
